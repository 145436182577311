"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BOARD_EVENTS = {
    JOIN_ROOM: "JOIN_ROOM",
    LEAVE_ROOM: "LEAVE_ROOM",
    USER_DISCONNECTS: "USER_DISCONNECTS",
    USER_RECONNECT: "USER_RECONNECT",
    USER_TOGGLE_ACTIVE: "USER_TOGGLE_ACTIVE",
    //----------------------------------------------------------------
    // BOARD EVENTS
    //----------------------------------------------------------------
    BOARD_UPDATE_ITEM_WIDTH: "BOARD_UPDATE_ITEM_WIDTH",
    BOARD_IMPORT_TEMPLATE: "BOARD_IMPORT_TEMPLATE",
    BOARD_IMPORT_AI_FILES: "BOARD_IMPORT_AI_FILES",
    TOGGLE_COMPLETE_BOARD: "TOGGLE_COMPLETE_BOARD",
    TOGGLE_IS_MANUEL: "TOGGLE_IS_MANUEL",
    TRIGGER_REFETCH: "TRIGGER_REFETCH",
    TOGGLE_INCLUDE_HOLIDAYS: "TOGGLE_INCLUDE_HOLIDAYS",
    UPDATE_HOLIDAY_COUNTRY: "UPDATE_HOLIDAY_COUNTRY",
    //----------------------------------------------------------------
    // TASK GROUP EVENTS
    //----------------------------------------------------------------
    POST_TASK_GROUP: "POST_TASK_GROUP",
    PUT_TASK_GROUP: "PUT_TASK_GROUP",
    DELETE_TASK_GROUP: "DELETE_TASK_GROUP",
    COLLAPSE_TASK_GROUP: "COLLAPSE_TASK_GROUP",
    DUPLICATE_TASK_GROUP: "DUPLICATE_TASK_GROUP",
    EXPORT_TASK_GROUP: "EXPORT_TASK_GROUP",
    UPDATE_TASK_GROUP_INDEX: "UPDATE_TASK_GROUP_INDEX",
    UPDATE_TASK_GROUP_MANUEL_INDEX: "UPDATE_TASK_GROUP_MANUEL_INDEX",
    TOGGLE_COMPLETE_TASK_GROUP: "TOGGLE_COMPLETE_TASK_GROUP",
    //----------------------------------------------------------------
    // TASK EVENTS
    //----------------------------------------------------------------
    POST_TASK: "POST_TASK",
    PUT_TASK: "PUT_TASK",
    DELETE_TASK: "DELETE_TASK",
    UPDATE_TASK_INDEX: "UPDATE_TASK_INDEX",
    TOGGLE_EXPAND_TASK: "TOGGLE_EXPAND_TASK",
    MOVE_TO_TASK_GROUP: "MOVE_TO_TASK_GROUP",
    DUPLICATE_TASK: "DUPLICATE_TASK",
    DUPLICATE_MULTIPLE_TASKS: "DUPLICATE_MULTIPLE_TASKS",
    MOVE_TO_OTHER_PARENT: "MOVE_TO_OTHER_PARENT",
    CONVERT_SUBITEM_TO_TASK: "CONVERT_SUBITEM_TO_TASK",
    CONVERT_ITEM_TO_SUBTASK: "CONVERT_ITEM_TO_SUBTASK",
    UPDATE_MANUEL_INDEX: "UPDATE_MANUEL_INDEX",
    //----------------------------------------------------------------
    // COLUMN EVENTS
    //----------------------------------------------------------------
    POST_COLUMN: "POST_COLUMN",
    CHANGE_COLUMN_TITLE: "CHANGE_COLUMN_TITLE",
    CHANGE_COLUMN_DESCRIPTION: "CHANGE_COLUMN_DESCRIPTION",
    DELETE_COLUMN: "DELETE_COLUMN",
    UPDATE_COLUMN_INDEX: "UPDATE_COLUMN_INDEX",
    UPDATE_COLUMN_WIDTH: "UPDATE_COLUMN_WIDTH",
    AGGREGATE_NUMBER_COLUMN_CHANGE: "AGGREGATE_NUMBER_COLUMN_CHANGE",
    AGGREGATE_DATE_COLUMN_CHANGE: "AGGREGATE_DATE_COLUMN_CHANGE",
    AGGREGATE_DATE_RANGE_COLUMN_CHANGE: "AGGREGATE_DATE_RANGE_COLUMN_CHANGE",
    //----------------------------------------------------------------
    // CELL EVENTS
    //----------------------------------------------------------------
    CLEAR_CELL: "CLEAR_CELL",
    STATUS_CELL: "STATUS_CELL",
    PERSON_CELL: "PERSON_CELL",
    TEXT_CELL: "TEXT_CELL",
    NUMBER_CELL: "NUMBER_CELL",
    DATE_CELL: "DATE_CELL",
    DATE_RANGE_CELL: "DATE_RANGE_CELL",
    CHECKBOX_CELL: "CHECKBOX_CELL",
    ADD_TIME_TRACKING_CELL: "ADD_TIME_TRACKING_CELL",
    EDIT_TIME_TRACKING_CELL: "EDIT_TIME_TRACKING_CELL",
    REMOVE_TIME_TRACKING_CELL: "REMOVE_TIME_TRACKING_CELL",
    CLOCK_CELL: "CLOCK_CELL",
    LONG_TEXT_CELL: "LONG_TEXT_CELL",
    UPLOAD_FILE_CELL: "UPLOAD_FILE_CELL",
    CHANGE_FILES_IN_CELL: "CHANGE_FILES_IN_CELL",
    LINK_CELL: "LINK_CELL",
    DROP_DOWN_CELL: "DROP_DOWN_CELL",
    LABEL_CELL: "LABEL_CELL",
    COUNTRY_CELL: "COUNTRY_CELL",
    PRIORITY_CELL: "PRIORITY_CELL",
    UPDATE_CELL_LABELS: "UPDATE_CELL_LABELS",
    FETCH_LATEST_COMMENT: "FETCH_LATEST_COMMENT",
    //----------------------------------------------------------------
    // Client WebSocket Events
    //----------------------------------------------------------------
    TASK_COMMENT_STORE: "TASK_COMMENT_STORE",
    TASK_COMMENT_REMOVE: "TASK_COMMENT_REMOVE",
    //----------------------------------------------------------------
    // Client WebSocket Events
    //----------------------------------------------------------------
    BOARD_CLIENT_NOTIFY_CHANGES: "BOARD_CLIENT_NOTIFY_CHANGES",
    BOARD_CLIENT_COMPLETE: "BOARD_CLIENT_COMPLETE",
    BOARD_CLIENT_TASK_COMPLETE: "BOARD_CLIENT_TASK_COMPLETE",
    //----------------------------------------------------------------
    // VIEW EVENTS
    //----------------------------------------------------------------
    CHANGE_VIEW_TITLE: "CHANGE_VIEW_TITLE",
    SET_VIEW_DEFAULT: "SET_VIEW_DEFAULT",
    DELETE_VIEW: "DELETE_VIEW",
    ADD_VIEW: "ADD_VIEW",
    //----------------------------------------------------------------
    // AUTOMATION EVENTS
    //---------------------------------------------
    STORE_AUTOMATION: "STORE_AUTOMATION",
    UPDATE_AUTOMATION: "UPDATE_AUTOMATION",
    REMOVE_AUTOMATION: "REMOVE_AUTOMATION",
    GET_STATUS_LABEL: "GET_STATUS_LABEL",
    AUTOMATION_TOGGLE_ACTIVE: "AUTOMATION_TOGGLE_ACTIVE",
    SERVER_DEACTIVATE_AUTOMATION: "SERVER_DEACTIVATE_AUTOMATION",
    //----------------------------------------------------------------
    // GANTT EVENTS
    //----------------------------------------------------------------
    HANDLE_GANTT_CHANGES: "HANDLE_GANTT_CHANGES",
    GET_GANTT_DATE_RANGE: "GET_GANTT_DATE_RANGE",
    HANDLE_GANTT_DATE_RANGE: "HANDLE_GANTT_DATE_RANGE",
    IMANAGE_UPDATE_DOCUMENT_SUCCESS: "IMANAGE_UPDATE_DOCUMENT_SUCCESS",
};
exports.default = BOARD_EVENTS;
