"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccess = exports.RedirectRules = void 0;
var auth0_react_1 = require("@auth0/auth0-react");
var react_router_dom_1 = require("react-router-dom");
var AccessTypes = {
    automation: "automation",
    taskBoards: "taskBoards",
    workspaces: "workspaces",
    timelines: "timelines",
    lookup: "lookup",
    deadlines: "deadlines",
    juristiq: "juristiq",
};
var noAccessPathMap = {
    automation: "app/paywall/wa",
    taskBoards: "app/paywall/tb",
    workspaces: "app/paywall/w",
    timelines: "app/paywall/t",
    lookup: "app/paywall/l",
    deadlines: "app/paywall/d",
    juristiq: "app/paywall/jq",
};
var accessPathMap = {
    automation: "app/workspaces",
    taskBoards: "app/taskBoards",
    workspaces: "app/workspaces",
    timelines: "app/timelines",
    lookup: "app/lookup",
    deadlines: "app/deadline",
    juristiq: "app/juristiq",
};
exports.RedirectRules = [
    // taskBoards
    {
        route: { path: "*boards*" },
        only: [AccessTypes.taskBoards],
        redirect: AccessTypes.taskBoards,
    },
    // workspace
    {
        route: { path: "*workspaces*" },
        only: [AccessTypes.workspaces],
        redirect: AccessTypes.workspaces,
    },
    {
        route: { path: "*nodes*" },
        only: [AccessTypes.workspaces],
        redirect: AccessTypes.workspaces,
    },
    {
        route: { path: "*attributes*" },
        only: [AccessTypes.workspaces],
        redirect: AccessTypes.workspaces,
    },
    {
        route: { path: "*relationships*" },
        only: [AccessTypes.workspaces],
        redirect: AccessTypes.workspaces,
    },
    {
        route: { path: "*alerts*" },
        only: [AccessTypes.automation],
        redirect: AccessTypes.automation,
    },
    {
        route: { path: "*conditions*" },
        only: [AccessTypes.automation],
        redirect: AccessTypes.automation,
    },
    {
        route: { path: "*outputs*" },
        only: [AccessTypes.automation],
        redirect: AccessTypes.automation,
    },
    // timeline
    {
        route: { path: "*/timelin*" },
        only: [AccessTypes.timelines],
        redirect: AccessTypes.timelines,
    },
    {
        route: { path: "*timelines*" },
        only: [AccessTypes.timelines],
        redirect: AccessTypes.timelines,
    },
    // timeline or workspace
    {
        route: { path: "*people*" },
        only: [AccessTypes.timelines, AccessTypes.workspaces],
        redirect: AccessTypes.timelines,
    },
    {
        route: { path: "*documents*" },
        only: [AccessTypes.timelines, AccessTypes.workspaces],
        redirect: AccessTypes.timelines,
    },
    // lookup
    {
        route: { path: "*lookup*" },
        only: [AccessTypes.lookup],
        redirect: AccessTypes.lookup,
    },
    // JuristIQ
    {
        route: { path: "*juristiq*" },
        only: [AccessTypes.juristiq],
        redirect: AccessTypes.juristiq,
    },
];
var AccessRedirectRules = [
    // hasAccess
    {
        route: { path: "*paywall/wa*" },
        only: [AccessTypes.automation],
        redirect: AccessTypes.automation,
    },
    {
        route: { path: "*paywall/w*" },
        only: [AccessTypes.workspaces],
        redirect: AccessTypes.workspaces,
    },
    {
        route: { path: "*paywall/tb*" },
        only: [AccessTypes.taskBoards],
        redirect: AccessTypes.taskBoards,
    },
    {
        route: { path: "*paywall/t*" },
        only: [AccessTypes.timelines],
        redirect: AccessTypes.timelines,
    },
    {
        route: { path: "*paywall/l*" },
        only: [AccessTypes.lookup],
        redirect: AccessTypes.lookup,
    },
    {
        route: { path: "*paywall/jq*" },
        only: [AccessTypes.juristiq],
        redirect: AccessTypes.juristiq,
    },
];
// Function to check if the current pathname matches any rule
function findMatchingRule(rules, pathname) {
    return rules.find(function (rule) {
        // Use a regular expression to match the path with the pattern
        var pattern = new RegExp(rule.route.path.replace(/\*/g, ".*"));
        if (pattern.test(pathname)) {
            return rule; // Return the matching rule
        }
        return null; // Return null if no match is found
    });
}
// useRedirect hook
var useAccess = function () {
    var _a, _b;
    var user = (0, auth0_react_1.useAuth0)().user;
    var meta = user === null || user === void 0 ? void 0 : user["https://juristic.io/meta"];
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    if (!meta)
        return {};
    if (meta.dbUser.type !== "lawyer")
        return {};
    var accesses = meta.dbUser.accesses;
    var redirectRule = findMatchingRule(exports.RedirectRules, pathname);
    var hasMissingProps = !accesses || !redirectRule;
    var only = (_a = redirectRule === null || redirectRule === void 0 ? void 0 : redirectRule.only) === null || _a === void 0 ? void 0 : _a.map(function (access) { return AccessTypes[access]; });
    var shouldRedirect = only && !only.some(function (access) { return accesses.includes(access); });
    if (shouldRedirect) {
        if (hasMissingProps)
            return {};
        var replaceWith = noAccessPathMap[redirectRule.redirect];
        var redirectPath = replaceWith + "/";
        return { redirect: redirectPath };
    }
    var accessRedirectRule = findMatchingRule(AccessRedirectRules, pathname);
    var hasMissingAccessProps = !accesses || !accessRedirectRule;
    var accessOnly = (_b = accessRedirectRule === null || accessRedirectRule === void 0 ? void 0 : accessRedirectRule.only) === null || _b === void 0 ? void 0 : _b.map(function (access) { return AccessTypes[access]; });
    var accessShouldRedirect = accessOnly && accessOnly.some(function (access) { return accesses.includes(access); });
    if (accessShouldRedirect) {
        if (hasMissingAccessProps)
            return {};
        var replaceWith = accessPathMap[accessRedirectRule.redirect];
        var redirectPath = replaceWith + "/";
        return { redirect: redirectPath };
    }
    return {};
};
exports.useAccess = useAccess;
