"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TIMELINE_EVENTS = {
    JOIN_ROOM: "JOIN_ROOM",
    LEAVE_ROOM: "LEAVE_ROOM",
    USER_POSITION: "USER_POSITION",
    USER_POSITION_RELATIVE: "USER_POSITION_RELATIVE",
    USER_DISCONNECTS: "USER_DISCONNECTS",
    USER_TOGGLE_ACTIVE: "USER_TOGGLE_ACTIVE",
    USER_RECONNECT: "USER_RECONNECT",
    REMOVE_CURSOR: "REMOVE_CURSOR",
    GET_EDITED_ELEMENTS: "GET_EDITED_ELEMENTS",
    EDIT_ELEMENTS_STARTED: "EDIT_ELEMENTS_STARTED",
    EDIT_ELEMENTS_FINISHED: "EDIT_ELEMENTS_FINISHED",
    TIMELINE_UPDATE: "TIMELINE_UPDATE",
    TIMELINE_DELETED: "TIMELINE_DELETED",
    REFETCH_TIMELINE_ELEMENTS: "REFETCH_TIMELINE_ELEMENTS",
    // elements
    TIMELINE_ELEMENT_POSITIONS: "TIMELINE_ELEMENT_POSITIONS",
    TIMELINE_ELEMENT_SIZE: "TIMELINE_ELEMENT_SIZE",
    // comments
    TIMELINE_COMMENT_STORE: "TIMELINE_COMMENT_STORE",
    TIMELINE_COMMENT_REMOVE: "TIMELINE_COMMENT_REMOVE",
    // Upload
    TIMELINE_UPLOAD_DOCUMENT_COMPLETED: "TIMELINE_UPLOAD_DOCUMENT_COMPLETED",
    TIMELINE_UPLOAD_DOCUMENT_FAILED: "TIMELINE_UPLOAD_DOCUMENT_FAILED",
    TIMELINE_UPLOAD_DOCUMENT_PROGRESS: "TIMELINE_UPLOAD_DOCUMENT_PROGRESS",
    TIMELINE_UPLOAD_DOCUMENT_STARTED: "TIMELINE_UPLOAD_DOCUMENT_STARTED",
    TIMELINE_EMAIL_UPLOAD_COMPLETED: "TIMELINE_EMAIL_UPLOAD_COMPLETED",
    TIMELINE_EMAIL_UPLOAD_FAILED: "TIMELINE_EMAIL_UPLOAD_FAILED",
    TIMELINE_EMAIL_UPLOAD_PROGRESS: "TIMELINE_EMAIL_UPLOAD_PROGRESS",
    TIMELINE_EMAIL_UPLOAD_STARTED: "TIMELINE_EMAIL_UPLOAD_STARTED",
    // JURISTIQ
    TIMELINE_JURISTIQ_DATE_FINDER_COMPLETED: "TIMELINE_JURISTIQ_DATE_FINDER_COMPLETED",
    TIMELINE_JURISTIQ_DATE_FINDER_FAILED: "TIMELINE_JURISTIQ_DATE_FINDER_FAILED",
    TIMELINE_JURISTIQ_DATE_FINDER_PROGRESS: "TIMELINE_JURISTIQ_DATE_FINDER_PROGRESS",
    TIMELINE_JURISTIQ_DATE_FINDER_STARTED: "TIMELINE_JURISTIQ_DATE_FINDER_STARTED",
    TIMELINE_GENERATE_DOCUMENT_BUNDLE_STARTED: "TIMELINE_GENERATE_DOCUMENT_BUNDLE_STARTED",
    TIMELINE_GENERATE_DOCUMENT_BUNDLE_PROGRESS: "TIMELINE_GENERATE_DOCUMENT_BUNDLE_PROGRESS",
    TIMELINE_GENERATE_DOCUMENT_BUNDLE_COMPLETED: "TIMELINE_GENERATE_DOCUMENT_BUNDLE_COMPLETED",
    TIMELINE_GENERATE_DOCUMENT_BUNDLE_FAILED: "TIMELINE_GENERATE_DOCUMENT_BUNDLE_FAILED",
    TIMELINE_DOWNLOAD_PDF_STARTED: "TIMELINE_DOWNLOAD_PDF_STARTED",
    TIMELINE_DOWNLOAD_PDF_PROGRESS: "TIMELINE_DOWNLOAD_PDF_PROGRESS",
    TIMELINE_DOWNLOAD_PDF_COMPLETED: "TIMELINE_DOWNLOAD_PDF_COMPLETED",
    TIMELINE_DOWNLOAD_PDF_FAILED: "TIMELINE_DOWNLOAD_PDF_FAILED",
    TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_COMPLETED: "TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_COMPLETED",
    TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_FAILED: "TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_FAILED",
    TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_PROGRESS: "TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_PROGRESS",
    TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_STARTED: "TIMELINE_JURISTIQ_PARTICULARS_OF_CLAIM_STARTED",
    // nodes
    TIMELINE_NODE_UPDATE: "TIMELINE_NODE_UPDATE",
    TIMELINE_NODE_DELETED: "TIMELINE_NODE_DELETED",
    // sticky notes
    TIMELINE_STICKY_NOTE_STORE: "TIMELINE_STICKY_NOTE_STORE",
    TIMELINE_STICKY_NOTE_UPDATE: "TIMELINE_STICKY_NOTE_UPDATE",
    TIMELINE_STICKY_NOTE_DESTROY: "TIMELINE_STICKY_NOTE_DESTROY",
    // other
    FETCH_DROP_DOWN_OPTIONS: "FETCH_DROP_DOWN_OPTIONS",
    TIMELINE_DOCUMENT_REMOVE_ANALYSED_NODES: "TIMELINE_DOCUMENT_REMOVE_ANALYSED_NODES",
    IMANAGE_UPDATE_DOCUMENT_SUCCESS: "IMANAGE_UPDATE_DOCUMENT_SUCCESS",
};
exports.default = TIMELINE_EVENTS;
