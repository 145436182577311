"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var lodash_1 = require("lodash");
var searchKeys = {
    search: "q",
    rowPerPage: "rpp",
    page: "p",
    tab: "t",
};
var useUrlParamsTableState = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.prefix, prefix = _c === void 0 ? "" : _c, _d = _b.defaultRowPerPage, defaultRowPerPage = _d === void 0 ? 10 : _d;
    var trimmedPrefix = prefix.trim();
    var _e = (0, react_router_dom_1.useSearchParams)(), searchParams = _e[0], setSearchParams = _e[1];
    var searchTextprefix = trimmedPrefix + searchKeys.search;
    var rowsPerPagePrefix = trimmedPrefix + searchKeys.rowPerPage;
    var pagePrefix = trimmedPrefix + searchKeys.page;
    var setNewParams = (0, react_1.useCallback)(function (param, paramValue) {
        var updatedSearchParams = new URLSearchParams(searchParams);
        if (typeof param === "string") {
            if (paramValue == null || paramValue === "") {
                updatedSearchParams.delete(param);
            }
            else {
                updatedSearchParams.set(param, paramValue.toString());
            }
        }
        else if (typeof param === "object") {
            Object.keys(param).forEach(function (key) {
                var value = param[key];
                if (value == null || value === "") {
                    updatedSearchParams.delete(key);
                }
                else {
                    updatedSearchParams.set(key, value.toString());
                }
            });
        }
        setSearchParams(updatedSearchParams);
    }, [searchParams, setSearchParams]);
    var handleChangePage = function (currentPage) {
        setNewParams(pagePrefix, currentPage);
    };
    var handleChangeRows = function (numberOfRows) {
        setNewParams(String(rowsPerPagePrefix), numberOfRows);
    };
    var ifPriorSearch = function () { return Boolean(searchParams.get(searchTextprefix)); };
    var handleSearchChange = (0, react_1.useCallback)((0, lodash_1.debounce)(function (searchText) {
        var trimmedSearchText = searchText ? searchText.trim() : "";
        setNewParams(searchTextprefix, trimmedSearchText);
    }, 500), [prefix, setNewParams]);
    var exportedRowsPerPage = Number(searchParams.get(rowsPerPagePrefix)) || Number(defaultRowPerPage);
    var exportedPage = Number(searchParams.get(pagePrefix)) || 0;
    var searchText = searchParams.get(searchTextprefix) || "";
    var searchOpen = !!searchParams.get(searchTextprefix);
    var tabParam = searchParams.get(searchKeys.tab);
    return {
        setNewParams: setNewParams,
        handleChangePage: handleChangePage,
        handleChangeRows: handleChangeRows,
        ifPriorSearch: ifPriorSearch,
        handleSearchChange: handleSearchChange,
        exportedPage: exportedPage,
        exportedRowsPerPage: exportedRowsPerPage,
        searchText: searchText,
        searchOpen: searchOpen,
        tabParam: tabParam,
    };
};
exports.default = useUrlParamsTableState;
