"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reduceGanttChanges = exports.recursivelyApplyLabelChangesToTask = exports.duplicateMultipleTasks = exports.handleDeleteColumn = exports.handleDeleteTaskGroup = exports.handleIndexUpdate = exports.handleClearCell = exports.handleMultipleColumnsSuccess = exports.handleMultipleCellsSuccess = exports.handleCellSuccess = exports.handleDuplicateTask = exports.handleConvertItemToSubTask = exports.handleConvertSubItemToTask = exports.handleMoveTaskToOtherParent = exports.handleMoveTask = exports.handlePutTask = exports.handlePostTaskFailed = exports.handlePostTaskLoading = exports.handlePostTask = exports.stopCellLoading = exports.stopLoading = void 0;
var arrayMove_1 = require("@utils/arrayMove");
var dummyTaskValues = {
    commentCount: 0,
    expanded: false,
    tasks: [],
    manuelIndex: null,
};
var stopLoading = function (currentLoadings, loadingType) {
    var _a;
    return loadingType ? __assign(__assign({}, currentLoadings), (_a = {}, _a[loadingType] = false, _a)) : currentLoadings;
};
exports.stopLoading = stopLoading;
var stopCellLoading = function (currentLoadings, loadingId) {
    var _a;
    return loadingId ? __assign(__assign({}, currentLoadings), (_a = {}, _a[loadingId] = false, _a)) : currentLoadings;
};
exports.stopCellLoading = stopCellLoading;
var insertTaskAtGroupLevel = function (taskGroup, task, uuid) {
    var tasks = __spreadArray([], taskGroup.tasks, true);
    if (uuid) {
        var replaceIndex = tasks.findIndex(function (t) { return t.loadingPlaceholder === uuid; });
        if (~replaceIndex) {
            tasks[replaceIndex] = task;
            return tasks;
        }
    }
    if (~task.index) {
        tasks.splice(task.index, 0, task);
        // increase index of all above
        for (var i = task.index + 1; i < tasks.length; i++) {
            tasks[i].index = i;
        }
    }
    else {
        tasks.splice(tasks.length - 1, 0, task);
    }
    return tasks;
};
function insertTaskInParent(taskGroup, task, expand, uuid) {
    return taskGroup.tasks.map(function (parentTask) {
        if (parentTask.id === task.parentId) {
            var newParentTasks = __spreadArray([], parentTask.tasks, true);
            if (uuid) {
                var replaceIndex = newParentTasks.findIndex(function (t) { return t.loadingPlaceholder === uuid; });
                if (replaceIndex !== -1) {
                    newParentTasks[replaceIndex] = task;
                    return __assign(__assign({}, parentTask), { tasks: newParentTasks, expanded: expand || parentTask.expanded });
                }
            }
            if (~task.index) {
                newParentTasks.splice(task.index, 0, task);
                // increase index of all above
                for (var i = task.index + 1; i < newParentTasks.length; i++) {
                    newParentTasks[i].index = i;
                }
            }
            else {
                newParentTasks.splice(newParentTasks.length - 1, 0, task);
            }
            return __assign(__assign({}, parentTask), { tasks: newParentTasks, expanded: expand || parentTask.expanded });
        }
        return parentTask;
    });
}
var handlePostTask = function (state, action) {
    var updatedTaskGroups = state.taskGroups.map(function (taskGroup) {
        if (taskGroup.id !== action.taskGroupId) {
            return taskGroup;
        }
        var newTasks = action.task.parentId
            ? insertTaskInParent(taskGroup, action.task, false, action.uuid)
            : insertTaskAtGroupLevel(taskGroup, action.task, action.uuid);
        return __assign(__assign({}, taskGroup), { tasks: newTasks });
    });
    return __assign(__assign({}, state), { taskGroups: updatedTaskGroups });
};
exports.handlePostTask = handlePostTask;
var handlePostTaskLoading = function (state, action) {
    var updatedTaskGroups = state.taskGroups.map(function (taskGroup) {
        if (taskGroup.id !== action.taskGroupId) {
            return taskGroup;
        }
        var dummyTask = __assign(__assign({}, dummyTaskValues), { id: action.uuid, loadingPlaceholder: action.uuid, parentId: action.parentId, title: action.title, index: action.index || -1 });
        var newTasks = dummyTask.parentId
            ? insertTaskInParent(taskGroup, dummyTask, false, action.uuid)
            : insertTaskAtGroupLevel(taskGroup, dummyTask, action.uuid);
        return __assign(__assign({}, taskGroup), { tasks: newTasks });
    });
    return __assign(__assign({}, state), { taskGroups: updatedTaskGroups });
};
exports.handlePostTaskLoading = handlePostTaskLoading;
var handlePostTaskFailed = function (state, action) {
    var updatedTaskGroups = state.taskGroups.map(function (taskGroup) {
        if (taskGroup.id !== action.taskGroupId) {
            return taskGroup;
        }
        if (action.parentId) {
            var newTasks_1 = taskGroup.tasks.map(function (task) {
                if (task.id === action.parentId) {
                    var newSubTasks = task.tasks.filter(function (subtask) { return subtask.id !== action.uuid; });
                    return __assign(__assign({}, task), { tasks: newSubTasks });
                }
                return task;
            });
            return __assign(__assign({}, taskGroup), { tasks: newTasks_1 });
        }
        var newTasks = taskGroup.tasks.filter(function (task) { return task.id !== action.uuid; });
        return __assign(__assign({}, taskGroup), { tasks: newTasks });
    });
    return __assign(__assign({}, state), { taskGroups: updatedTaskGroups });
};
exports.handlePostTaskFailed = handlePostTaskFailed;
var handlePutTask = function (state, action) {
    var updatedTaskGroups = state.taskGroups.map(function (taskGroup) {
        if (taskGroup.id !== action.taskGroupId) {
            return taskGroup;
        }
        var newTasks = taskGroup.tasks.map(function (task) {
            if (task.id === action.task.id) {
                return action.task;
            }
            if (action.task.parentId && task.id === action.task.parentId) {
                return __assign(__assign({}, task), { tasks: task.tasks.map(function (subtask) {
                        return subtask.id === action.task.id ? action.task : subtask;
                    }) });
            }
            return task;
        });
        return __assign(__assign({}, taskGroup), { tasks: newTasks });
    });
    return __assign(__assign({}, state), { loadings: (0, exports.stopLoading)(state.loadings, action.loadingType), taskGroups: updatedTaskGroups });
};
exports.handlePutTask = handlePutTask;
var handleMoveTask = function (state, action) { return (__assign(__assign({}, state), { loadings: (0, exports.stopLoading)(state.loadings, action.loadingType), taskGroups: state.taskGroups.map(function (taskGroup) { return action.taskGroups[taskGroup.id] || taskGroup; }) })); };
exports.handleMoveTask = handleMoveTask;
var handleMoveTaskToOtherParent = function (state, action) { return (__assign(__assign({}, state), { loadings: (0, exports.stopLoading)(state.loadings, action.loadingType), taskGroups: state.taskGroups.map(function (taskGroup) {
        var tg = action.taskGroups[taskGroup.id] || taskGroup;
        tg.tasks = tg.tasks.map(function (task) {
            return task.id === action.parentId ? __assign(__assign({}, task), { expanded: true }) : task;
        });
        return tg;
    }) })); };
exports.handleMoveTaskToOtherParent = handleMoveTaskToOtherParent;
var handleConvertSubItemToTask = function (state, action) { return (__assign(__assign({}, state), { loadings: (0, exports.stopLoading)(state.loadings, action.loadingType), taskGroups: state.taskGroups.map(function (taskGroup) { return action.taskGroups[taskGroup.id] || taskGroup; }) })); };
exports.handleConvertSubItemToTask = handleConvertSubItemToTask;
var handleConvertItemToSubTask = function (state, action) { return (__assign(__assign({}, state), { loadings: (0, exports.stopLoading)(state.loadings, action.loadingType), taskGroups: state.taskGroups.map(function (taskGroup) {
        var tg = action.taskGroups[taskGroup.id] || taskGroup;
        tg.tasks = tg.tasks.map(function (task) {
            return task.id === action.parentId ? __assign(__assign({}, task), { expanded: true }) : task;
        });
        return tg;
    }) })); };
exports.handleConvertItemToSubTask = handleConvertItemToSubTask;
var handleDuplicateTask = function (state, action) {
    var updatedTaskGroups = state.taskGroups
        .map(function (taskGroup) {
        if (taskGroup.id === action.taskGroupId) {
            if (action.task.parentId) {
                var newTasks_2 = insertTaskInParent(taskGroup, action.task);
                return __assign(__assign({}, taskGroup), { tasks: newTasks_2 });
            }
            var newTasks = insertTaskAtGroupLevel(taskGroup, action.task);
            return __assign(__assign({}, taskGroup), { tasks: newTasks });
        }
        return taskGroup;
    })
        .sort(function (a, b) { return a.index - b.index; });
    return __assign(__assign({}, state), { loadings: (0, exports.stopLoading)(state.loadings, action.loadingType), taskGroups: updatedTaskGroups });
};
exports.handleDuplicateTask = handleDuplicateTask;
var handleCellSuccess = function (state, action) {
    var updatedTaskGroups = state.taskGroups.map(function (taskGroup) {
        if (taskGroup.id !== action.taskGroupId) {
            return taskGroup;
        }
        var newTasks = taskGroup.tasks.map(function (task) {
            var _a;
            if (task.id === action.taskId) {
                return __assign(__assign({}, task), (_a = {}, _a[action.key] = action.value, _a));
            }
            var newSubTasks = task.tasks.map(function (subtask) {
                var _a;
                if (subtask.id === action.taskId) {
                    return __assign(__assign({}, subtask), (_a = {}, _a[action.key] = action.value, _a));
                }
                return subtask;
            });
            return __assign(__assign({}, task), { tasks: newSubTasks });
        });
        return __assign(__assign({}, taskGroup), { tasks: newTasks });
    });
    return __assign(__assign({}, state), { cellLoadings: (0, exports.stopCellLoading)(state.cellLoadings, action.loadingId), taskGroups: updatedTaskGroups });
};
exports.handleCellSuccess = handleCellSuccess;
var handleMultipleCellsSuccess = function (state, action) {
    var updatedTaskGroups = state.taskGroups.map(function (taskGroup) {
        var newGroupTasks = action.tasks.filter(function (task) { return task.groupId === taskGroup.id; });
        if (!newGroupTasks.length) {
            return taskGroup;
        }
        var newTasks = taskGroup.tasks.map(function (task) {
            var _a;
            var newSubTasks = task.tasks.map(function (subtask) {
                var _a;
                var newSubTask = newGroupTasks.find(function (t) { return t.id === subtask.id; });
                if (!newSubTask) {
                    return subtask;
                }
                return __assign(__assign({}, subtask), (_a = {}, _a[action.key] = newSubTask.value, _a));
            });
            var newTask = newGroupTasks.find(function (t) { return t.id === task.id; });
            if (task.id === (newTask === null || newTask === void 0 ? void 0 : newTask.id)) {
                return __assign(__assign({}, task), (_a = {}, _a[action.key] = newTask.value, _a.tasks = newSubTasks, _a));
            }
            return __assign(__assign({}, task), { tasks: newSubTasks });
        });
        return __assign(__assign({}, taskGroup), { tasks: newTasks });
    });
    return __assign(__assign({}, state), { cellLoadings: (0, exports.stopCellLoading)(state.cellLoadings, action.loadingId), taskGroups: updatedTaskGroups });
};
exports.handleMultipleCellsSuccess = handleMultipleCellsSuccess;
var handleMultipleColumnsSuccess = function (state, action) { return action.reduce(function (acc, a) { return (0, exports.handleMultipleCellsSuccess)(acc, a); }, state); };
exports.handleMultipleColumnsSuccess = handleMultipleColumnsSuccess;
var handleClearCell = function (state, action) {
    var updatedTaskGroups = state.taskGroups.map(function (taskGroup) {
        var newGroupTasks = action.tasks.filter(function (task) { return task.groupId === taskGroup.id; });
        if (!newGroupTasks.length) {
            return taskGroup;
        }
        var newTasks = taskGroup.tasks.map(function (task) {
            var _a;
            var newSubTasks = task.tasks.map(function (subtask) {
                var _a;
                var newSubTask = newGroupTasks.find(function (t) { return t.id === subtask.id; });
                if (!newSubTask) {
                    return subtask;
                }
                return __assign(__assign({}, subtask), (_a = {}, _a[action.key] = null, _a));
            });
            var newTask = newGroupTasks.find(function (t) { return t.id === task.id; });
            if (task.id === (newTask === null || newTask === void 0 ? void 0 : newTask.id)) {
                return __assign(__assign({}, task), (_a = {}, _a[action.key] = null, _a.tasks = newSubTasks, _a));
            }
            return __assign(__assign({}, task), { tasks: newSubTasks });
        });
        return __assign(__assign({}, taskGroup), { tasks: newTasks });
    });
    return __assign(__assign({}, state), { cellLoadings: (0, exports.stopCellLoading)(state.cellLoadings, action.loadingId), taskGroups: updatedTaskGroups });
};
exports.handleClearCell = handleClearCell;
function handleIndexUpdate(state, action) {
    var taskGroups = state.taskGroups.slice();
    var groupIndex = taskGroups.findIndex(function (group) { return group.id === action.taskGroupId; });
    if (!~groupIndex)
        return state;
    var group = __assign({}, taskGroups[groupIndex]);
    if (!group.tasks[action.from])
        return state;
    // The task we want to move is a `parent` task
    if (group.tasks[action.from].id === action.taskId) {
        var tasks_1 = (0, arrayMove_1.arrayMoveElement)(group.tasks, action.from, action.to);
        if (!tasks_1[action.to])
            return state;
        // Updating all indexes accordingly and immutably
        group.tasks = tasks_1.map(function (task, index) { return (__assign(__assign({}, task), { index: index })); });
        taskGroups[groupIndex] = group;
        return __assign(__assign({}, state), { taskGroups: taskGroups });
    }
    // We use action.from to prevent a double loop and find the parent by using the subTask's index
    var parentIndex = group.tasks.findIndex(function (parent) { var _a; return ((_a = parent.tasks[action.from]) === null || _a === void 0 ? void 0 : _a.id) === action.taskId; });
    if (!~parentIndex)
        return state;
    var parentTask = __assign({}, group.tasks[parentIndex]);
    var tasks = (0, arrayMove_1.arrayMoveElement)(parentTask.tasks, action.from, action.to);
    if (!tasks[action.to])
        return state;
    // Updating all indexes accordingly and immutably
    parentTask.tasks = tasks.map(function (task, index) { return (__assign(__assign({}, task), { index: index })); });
    group.tasks = __spreadArray([], group.tasks, true);
    group.tasks[parentIndex] = __assign({}, parentTask);
    var updatedTaskGroups = __spreadArray([], taskGroups, true);
    updatedTaskGroups[groupIndex] = __assign({}, group);
    return __assign(__assign({}, state), { taskGroups: updatedTaskGroups });
}
exports.handleIndexUpdate = handleIndexUpdate;
function handleDeleteTaskGroup(state, action) {
    var _a, _b;
    var grid = document.querySelector("[data-rbd-draggable-id=\"".concat(action.id, "\"]"));
    switch (action.type) {
        case "DELETE_TASK_GROUP_LOADING": {
            if (grid) {
                grid.style.opacity = "0.5";
                grid.style.pointerEvents = "none";
            }
            return __assign(__assign({}, state), { taskGroupLoadings: __assign(__assign({}, state.taskGroupLoadings), (_a = {}, _a[action.id] = true, _a)) });
        }
        case "DELETE_TASK_GROUP_FAILED": {
            if (grid) {
                grid.style.removeProperty("opacity");
                grid.style.removeProperty("pointer-events");
            }
            return __assign(__assign({}, state), { taskGroupLoadings: __assign(__assign({}, state.taskGroupLoadings), (_b = {}, _b[action.id] = false, _b)) });
        }
        default:
            return state;
    }
}
exports.handleDeleteTaskGroup = handleDeleteTaskGroup;
function handleDeleteColumn(state, action) {
    var _a, _b;
    switch (action.type) {
        case "DELETE_COLUMN_LOADING": {
            return __assign(__assign({}, state), { columnLoadings: __assign(__assign({}, state.columnLoadings), (_a = {}, _a[action.id] = true, _a)) });
        }
        case "DELETE_COLUMN_FAILED": {
            return __assign(__assign({}, state), { columnLoadings: __assign(__assign({}, state.columnLoadings), (_b = {}, _b[action.id] = false, _b)) });
        }
        default:
            return state;
    }
}
exports.handleDeleteColumn = handleDeleteColumn;
var duplicateMultipleTasks = function (state, action) {
    var updatedTaskGroups = state.taskGroups
        .map(function (taskGroup) {
        var tasks = action.tasks[taskGroup.id] || [];
        return tasks.reduce(function (acc, task) {
            var newTasks = task.parentId == null
                ? insertTaskAtGroupLevel(acc, task)
                : insertTaskInParent(acc, task);
            acc.tasks = newTasks;
            return acc;
        }, __assign({}, taskGroup));
    })
        .sort(function (a, b) { return a.index - b.index; });
    return __assign(__assign({}, state), { loadings: (0, exports.stopLoading)(state.loadings, action.loadingType), taskGroups: updatedTaskGroups });
};
exports.duplicateMultipleTasks = duplicateMultipleTasks;
function recursivelyApplyLabelChangesToTask(tasks, action) {
    var field = action.field, labels = action.labels;
    return tasks.map(function (task) {
        var _a;
        var _b;
        var cell = (_b = task[field]) === null || _b === void 0 ? void 0 : _b.cellValue;
        var val = cell
            ? labels.find(function (label) { return label.id === cell.columnStatusTypeId; })
            : undefined;
        return __assign(__assign(__assign({}, task), { tasks: recursivelyApplyLabelChangesToTask(task.tasks, action) }), (val && (_a = {},
            _a[field] = __assign(__assign({}, task[field]), { cellValue: __assign(__assign({}, cell), val) }),
            _a)));
    });
}
exports.recursivelyApplyLabelChangesToTask = recursivelyApplyLabelChangesToTask;
function reduceGanttChanges(state, action) {
    var _a = action.payload, boardViewId = _a.boardViewId, dateRangeColumn = _a.dateRangeColumn, deletedTaskGroups = _a.deletedTaskGroups, affectedTaskGroups = _a.affectedTaskGroups;
    var columns = __spreadArray([], state.columns, true);
    if (!columns.some(function (c) { return c.id === dateRangeColumn.id; })) {
        columns.splice(dateRangeColumn.index, 0, dateRangeColumn);
    }
    var taskGroups = __spreadArray([], state.taskGroups, true).filter(function (tg) { return !deletedTaskGroups.includes(tg.id); })
        .map(function (taskGroup) {
        if (Object.keys(affectedTaskGroups).includes(taskGroup.id.toString())) {
            return __assign(__assign({}, taskGroup), { tasks: affectedTaskGroups[taskGroup.id] });
        }
        return taskGroup;
    });
    return __assign(__assign({}, state), { taskGroups: taskGroups, columns: columns, views: state.views.map(function (view) {
            return view.id === boardViewId ? __assign(__assign({}, view), { dateRangeColumnId: dateRangeColumn.id }) : view;
        }), loadings: (0, exports.stopLoading)(state.loadings, action.loadingType) });
}
exports.reduceGanttChanges = reduceGanttChanges;
