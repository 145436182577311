"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var workspace_1 = require("@customTypes/reducers/workspace");
var handleExport_1 = require("@helpers/export/handleExport");
var constants_1 = require("@pages/Workspaces/constants");
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var constants_2 = require("@api/constants");
var useMeta = function (dispatch, rfInstance, label, handleVisability, user, id, stepId, tabId, PPTXRelated) {
    var _a = (0, react_1.useState)(true), shouldSnapToGrid = _a[0], setSnapToGrid = _a[1];
    var _b = (0, react_1.useState)(true), showHelperLines = _b[0], setHelperLines = _b[1];
    var toggleHelperLines = (0, react_1.useCallback)(function () { return setHelperLines(!showHelperLines); }, [showHelperLines]);
    var _c = (0, react_1.useState)(false), isSelectOndrag = _c[0], setIsSelectOnDrag = _c[1];
    var toggleSelectOnDrag = (0, react_1.useCallback)(function () { return setIsSelectOnDrag(function (prevVal) { return !prevVal; }); }, []);
    var handleVisabilityChange = (0, react_1.useCallback)(function () { return dispatch((0, workspaceActions_http_1.changeHandleVisability)(!handleVisability)); }, [handleVisability]);
    var handleAutoLayout = (0, react_1.useCallback)(function () {
        var nodeElements = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes();
        var edgeElements = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getEdges();
        var layoutedElements = (0, constants_1.getLayoutedElements)(nodeElements, edgeElements);
        dispatch((0, workspaceActions_http_1.layoutElements)(layoutedElements));
        user &&
            dispatch((0, workspaceActions_websocket_1.saveAutoLayout)(layoutedElements.nodes.filter(function (n) { return !(0, workspace_1.isTFlowPowerpointHelper)(n); })));
        // @ts-ignore it works for this purpose
        dispatch((0, workspaceActions_http_1.changeNodes)((0, reactflow_1.applyNodeChanges)([], layoutedElements.nodes)));
        setTimeout(function () {
            rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.fitView({ duration: 500, padding: 0.5 });
        }, 100);
    }, [rfInstance]);
    var handleImage = (0, react_1.useCallback)(function (type, _stopLoading) {
        return (0, handleExport_1.handleExport)(type, rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes, label, _stopLoading);
    }, [rfInstance, label]);
    var handlePowerpoint = (0, react_1.useCallback)(function (_stopLoading, all) {
        if (all === void 0) { all = false; }
        PPTXRelated &&
            id &&
            dispatch((0, workspaceActions_http_1.workspacePowerpoint)(user, id, label, stepId, tabId, PPTXRelated.showPPTXView, PPTXRelated.PPTXView, _stopLoading, all));
    }, [id, user, label, stepId, tabId, PPTXRelated]);
    var downloadWord = (0, react_1.useCallback)(function (startLoadingWord, _stopLoading) {
        startLoadingWord();
        var customer = (0, constants_2.getCustomer)(user);
        (0, handleExport_1.handleExport)(handleExport_1.ExportType.Word, rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes, label, _stopLoading, customer);
    }, [rfInstance, label, user]);
    return {
        handleAutoLayout: handleAutoLayout,
        handleImage: handleImage,
        handlePowerpoint: handlePowerpoint,
        shouldSnapToGrid: shouldSnapToGrid,
        setSnapToGrid: setSnapToGrid,
        helperLines: showHelperLines,
        toggleHelperLines: toggleHelperLines,
        handleVisabilityChange: handleVisabilityChange,
        downloadWord: downloadWord,
        isSelectOndrag: isSelectOndrag,
        toggleSelectOnDrag: toggleSelectOnDrag,
    };
};
exports.default = useMeta;
