"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
var react_1 = __importStar(require("react"));
var dotlottie_react_1 = require("@lottiefiles/dotlottie-react");
var react_text_transition_1 = __importStar(require("react-text-transition"));
var react_i18next_1 = require("react-i18next");
var material_1 = require("@mui/material");
function LongLoader(_a) {
    var _b = _a.bigFont, bigFont = _b === void 0 ? false : _b, _c = _a.size, size = _c === void 0 ? "20%" : _c, _d = _a.useRaccoon, useRaccoon = _d === void 0 ? true : _d, sxOverrides = _a.sxOverrides;
    var _e = (0, react_1.useState)(false), raccoon = _e[0], setRaccoon = _e[1];
    var _f = (0, react_1.useState)(0), textIndex = _f[0], setTextIndex = _f[1];
    var t = (0, react_i18next_1.useTranslation)().t;
    var loadingTexts = [
        t("loaders.1"),
        t("loaders.2"),
        t("loaders.3"),
        t("loaders.4"),
        t("loaders.5"),
        t("loaders.6"),
        t("loaders.7"),
        t("loaders.8"),
        t("loaders.9"),
        t("loaders.10"),
        t("loaders.11"),
        t("loaders.12"),
        t("loaders.13"),
        t("loaders.14"),
    ];
    (0, react_1.useEffect)(function () {
        var timer = setTimeout(function () {
            setRaccoon(true);
        }, 5000);
        return function () { return clearTimeout(timer); };
    }, []);
    (0, react_1.useEffect)(function () {
        var intervalId = setInterval(function () {
            setTextIndex(Math.floor(Math.random() * (loadingTexts.length - 0)));
        }, 8000);
        return function () { return clearInterval(intervalId); };
    }, []);
    return (react_1.default.createElement(material_1.Box, { sx: __assign({ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", overflow: "hidden" }, sxOverrides) },
        useRaccoon && raccoon && (react_1.default.createElement(react_text_transition_1.default, { text: loadingTexts[textIndex], springConfig: react_text_transition_1.presets.wobbly, inline: true, style: { marginBottom: 10, fontSize: bigFont ? 20 : 12 } })),
        react_1.default.createElement(dotlottie_react_1.DotLottieReact, { src: useRaccoon && raccoon
                ? "https://oos.eu-west-2.outscale.com/juristic-static-files/lottie/walk.json"
                : "https://oos.eu-west-2.outscale.com/juristic-static-files/lottie/loader.json", loop: true, autoplay: true, style: {
                width: size,
            } })));
}
exports.default = LongLoader;
