"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_CVR_NODES_LOADING = exports.WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_FAILED = exports.WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_SUCCESS = exports.WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_LOADING = exports.WORKSPACE_ADD_ELEMENTS_FAILED = exports.WORKSPACE_ADD_ELEMENTS_SUCCESS = exports.WORKSPACE_ADD_ELEMENTS_LOADING = exports.DELETE_WORKSPACE_ELEMENTS_FAILED = exports.DELETE_WORKSPACE_ELEMENTS_SUCCESS = exports.PUT_Z_INDEX_BACKWARD_FAILED = exports.PUT_Z_INDEX_BACKWARD_SUCCESS = exports.PUT_Z_INDEX_BACKWARD_LOADING = exports.PUT_Z_INDEX_FORWARD_FAILED = exports.PUT_Z_INDEX_FORWARD_SUCCESS = exports.PUT_Z_INDEX_FORWARD_LOADING = exports.PUT_Z_INDEX_TO_BACK_FAILED = exports.PUT_Z_INDEX_TO_BACK_SUCCESS = exports.PUT_Z_INDEX_TO_BACK_LOADING = exports.PUT_Z_INDEX_TO_FRONT_FAILED = exports.PUT_Z_INDEX_TO_FRONT_SUCCESS = exports.PUT_Z_INDEX_TO_FRONT_LOADING = exports.GET_NODE_VALUES_FAILED = exports.GET_NODE_VALUES_SUCCESS = exports.GET_RELATIONSHIP_VALUES_FAILED = exports.GET_RELATIONSHIP_VALUES_SUCCESS = exports.CLEAR_ATTRIBUTE_DROPDOWN = exports.GET_ATTRIBUTE_DROPDOWN_FAILED = exports.GET_ATTRIBUTE_DROPDOWN_SUCCESS = exports.GET_NODE_ATTRIBUTE_DROPDOWN_FAILED = exports.GET_NODE_ATTRIBUTE_DROPDOWN_SUCCESS = exports.GET_WORKSPACE_ELEMENTS_FAILED = exports.GET_WORKSPACE_ELEMENTS_SUCCESS = exports.GET_WORKSPACE_ELEMENTS_LOADING = exports.DELETE_WORKSPACE_FAILED = exports.DELETE_WORKSPACE_SUCCESS = exports.PUT_WORKSPACE_FAILED = exports.PUT_WORKSPACE_SUCCESS = exports.SHOW_WORKSPACE_FAILED = exports.SHOW_WORKSPACE_SUCCESS = exports.SHOW_WORKSPACE_LOADING = exports.POST_WORKSPACE_FAILED = exports.POST_WORKSPACE_SUCCESS = exports.POST_WORKSPACE_LOADING = exports.CLEAR_RECENT_WORKSPACES = exports.GET_RECENT_WORKSPACES_FAILED = exports.GET_RECENT_WORKSPACES_SUCCESS = exports.GET_RECENT_WORKSPACES_LOADING = exports.GET_WORKSPACES_FAILED = exports.GET_WORKSPACES_SUCCESS = exports.GET_WORKSPACES_LOADING = void 0;
exports.POST_EDGE_FAILED = exports.POST_EDGE_SUCCESS = exports.POST_EDGE_LOADING = exports.EDGE_ADD_TO_LIST = exports.SET_IS_WORKSPACE_PUBLIC = exports.SHARE_ORG_CHANGE = exports.CHANGE_TAGS = exports.ADD_LAWSUIT = exports.DESCRIPTION_CHANGE = exports.LABEL_CHANGE = exports.POST_STANDARD_NODE_FAILED = exports.POST_STANDARD_NODE_SUCCESS = exports.POST_STANDARD_NODE_LOADING = exports.PUT_SELECTED_ATTRIBUTE_OPTION_FAILED = exports.PUT_SELECTED_ATTRIBUTE_OPTION_SUCCESS = exports.POST_DROPDOWN_VALUE_FAILED = exports.POST_DROPDOWN_VALUE_SUCCESS = exports.SET_PUBLIC_ACCESS_FALSE = exports.IMPORT_WORKSPACE_FILES_FAILED = exports.IMPORT_WORKSPACE_FILES_SUCCESS = exports.IMPORT_WORKSPACE_FILES_LOADING = exports.PUBLIC_ACCESS_WORKSPACE_FAILED = exports.PUBLIC_ACCESS_WORKSPACE_SUCCESS = exports.PUBLIC_ACCESS_WORKSPACE_LOADING = exports.SHARE_WORKSPACE_FAILED = exports.SHARE_WORKSPACE_LOADING = exports.SHARE_WORKSPACE_SUCCESS = exports.DELETE_WORKSPACE_EDGES_FAILED = exports.DELETE_WORKSPACE_EDGES_SUCCESS = exports.DELETE_WORKSPACE_EDGES_LOADING = exports.DELETE_WORKSPACE_NODES_FAILED = exports.DELETE_WORKSPACE_NODES_SUCCESS = exports.DELETE_WORKSPACE_NODES_LOADING = exports.SAVE_WORKSPACE_FAILED = exports.SAVE_WORKSPACE_SUCCESS = exports.ANALYSE_OUTPUT_FAILED = exports.ANALYSE_OUTPUT_SUCCESS = exports.ANALYSE_OUTPUT_LOADING = exports.GET_WORKSPACE_NODE_ADDRESS_INFO_FAILED = exports.GET_WORKSPACE_NODE_ADDRESS_INFO_SUCCESS = exports.GET_WORKSPACE_NODE_ADDRESS_INFO_LOADING = exports.GET_WORKSPACE_NODE_COMPANY_DATA_FAILED = exports.GET_WORKSPACE_NODE_COMPANY_DATA_SUCCESS = exports.GET_WORKSPACE_NODE_COMPANY_DATA_LOADING = exports.NEW_BUILDING_BLOCKS_AND_TABS = exports.IMPORT_STRUCTURE_FAILED = exports.IMPORT_STRUCTURE_SUCCESS = exports.IMPORT_STRUCTURE_LOADING = exports.GET_CVR_NODES_FAILED = exports.GET_CVR_NODES_SUCCESS = void 0;
exports.WORKSPACE_UNDO_RESET = exports.WORKSPACE_REDO_PAYLOAD = exports.WORKSPACE_UNDO_PAYLOAD = exports.WORKSPACE_REDO = exports.WORKSPACE_UNDO = exports.CHANGE_EDGES = exports.CHANGE_NODES = exports.DO_NOT_SHOW_INTERNATIONAL_DISCLAIMER_AGAIN = exports.STOP_LOADING = exports.SET_SHOW_ADDRESS_INFO = exports.SET_PUBLIC_VISITED = exports.ANALYSIS_TEXT_CHANGE = exports.LAYOUT_ELEMENTS = exports.SHOW_HANDLES_CHANGE = exports.WORKSPACE_ANALYSIS_REVISION_FAILED = exports.WORKSPACE_ANALYSIS_REVISION_SUCCESS = exports.WORKSPACE_ANALYSIS_SAVE_FAILED = exports.WORKSPACE_ANALYSIS_SAVE_SUCCESS = exports.WORKSPACE_POST_ICON_FAILED = exports.WORKSPACE_POST_ICON_SUCCESS = exports.WORKSPACE_POST_ICON_LOADING = exports.WORKSPACE_POST_FILE_FAILED = exports.WORKSPACE_POST_FILE_SUCCESS = exports.WORKSPACE_POST_FILE_LOADING = exports.WORKSPACE_PUT_NODE_COLOR_SUCCESS = exports.WORKSPACE_NODE_ATTRIBUT_ADD_TO_LIST = exports.WORKSPACE_NODE_ADD_TO_LIST = exports.WORKSPACE_PUT_MULTIPLE_NODES_FAILED = exports.WORKSPACE_PUT_MULTIPLE_NODES_SUCCESS = exports.WORKSPACE_PUT_MULTIPLE_NODES_LOADING = exports.WORKSPACE_PUT_NODE_FAILED = exports.WORKSPACE_PUT_NODE_SUCCESS = exports.WORKSPACE_PUT_NODE_LOADING = exports.WORKSPACE_POST_NODE_FAILED = exports.WORKSPACE_POST_NODE_SUCCESS = exports.WORKSPACE_POST_NODE_LOADING = exports.EDGE_PATH_DRAG_FAILED = exports.EDGE_PATH_DRAG_SUCCESS = exports.EDGE_PATH_DRAG_LOADING = exports.EDGE_LABEL_MOVE_FAILED = exports.EDGE_LABEL_MOVE_SUCCESS = exports.EDGE_LABEL_MOVE_LOADING = exports.PUT_EDGE_POSITION_FAILED = exports.PUT_EDGE_POSITION_SUCCESS = exports.PUT_EDGE_POSITION_LOADING = exports.MAKE_STEP_FRAMES_ON_TAB_FAILED = exports.MAKE_STEP_FRAMES_ON_TAB_SUCCESS = exports.PUT_EDGE_FAILED = exports.PUT_EDGE_SUCCESS = exports.PUT_EDGE_LOADING = void 0;
exports.PUT_WORKSPACE_TAB_TITLE_LOADING = exports.PUT_WORKSPACE_TAB_TITLE_SUCCESS = exports.POST_WORKSPACE_TAB_FAILED = exports.POST_WORKSPACE_TAB_LOADING = exports.POST_WORKSPACE_TAB_SUCCESS = exports.GET_WORKSPACE_TABS_FAILED = exports.GET_WORKSPACE_TABS_LOADING = exports.GET_WORKSPACE_TABS_SUCCESS = exports.CLOSE_ALERT_PANEL = exports.OPEN_ALERT_PANEL = exports.TOGGLE_ALERT_PANEL = exports.CLOSE_REPORT_PANEL = exports.OPEN_REPORT_PANEL = exports.TOGGLE_REPORT_PANEL = exports.CLOSE_FRAME_PANEL = exports.OPEN_FRAME_PANEL = exports.TOGGLE_FRAME_PANEL = exports.TOGGLE_NODE_STICKY_UNDO_EVENT = exports.HARD_DELETE_WORKSPACE_ELEMENTS_SUCCESS = exports.HARD_DELETE_WORKSPACE_ELEMENTS_FAILED = exports.HARD_DELETE_WORKSPACE_ELEMENTS_LOADING = exports.CREATE_WORKSPACE = exports.CHANGE_MERGE_FIELD = exports.PUT_WORKSPACE_MERGE_FIELDS_FAILED = exports.PUT_WORKSPACE_MERGE_FIELDS_SUCCESS = exports.PUT_WORKSPACE_MERGE_FIELDS_LOADING = exports.GET_WORKSPACE_MERGE_FIELDS_FAILED = exports.GET_WORKSPACE_MERGE_FIELDS_SUCCESS = exports.GET_WORKSPACE_MERGE_FIELDS_LOADING = exports.SAVE_NODE_SIZE_FAILED = exports.SAVE_NODE_SIZE_SUCCESS = exports.SAVE_NODE_SIZE_LOADING = exports.SAVE_NODE_POSITION_FAILED = exports.SAVE_NODE_POSITION_SUCCESS = exports.SAVE_NODE_POSITION_LOADING = exports.WORKSPACE_PUT_STICKY_FAILED = exports.WORKSPACE_PUT_STICKY_SUCCESS = exports.WORKSPACE_PUT_STICKY_LOADING = exports.SOFT_DELETE_WORKSPACE_EDGES_FAILED = exports.SOFT_DELETE_WORKSPACE_EDGES_SUCCESS = exports.SOFT_DELETE_WORKSPACE_EDGES_LOADING = exports.SOFT_DELETE_WORKSPACE_NODES_FAILED = exports.SOFT_DELETE_WORKSPACE_NODES_SUCCESS = exports.SOFT_DELETE_WORKSPACE_NODES_LOADING = exports.CLEAR_UNSETTLED_LABELS = exports.CLEAR_STATE = exports.CLOSE_INFO_MODAL = exports.OPEN_INFO_MODAL = exports.WORKSPACE_UNDO_APPEND = exports.WORKSPACE_REDO_RESET = void 0;
exports.USER_JOIN_ROOM = exports.PUT_WORKSPACE_TAB_TITLE_FOR_WORKSPACE = exports.POST_WORKSPACE_TAB_FOR_WORKSPACE = exports.REMOVE_WORKSPACE_TAB_FOR_WORKSPACE = exports.WORKSPACE_NODE_TOGGLE_EXIT_LEVEL_PWC_FAILED = exports.WORKSPACE_NODE_TOGGLE_EXIT_LEVEL_PWC = exports.WORKSPACE_CHANGE_POWERPOINT_HELPER_SIZE = exports.WORKSPACE_DUPLICATE_FAILED = exports.WORKSPACE_DUPLICATE_SUCCESS = exports.WORKSPACE_DUPLICATE_LOADING = exports.WORKSPACE_DESELECT_EDGES = exports.CLOSE_MERGE_FIELDS_MODAL = exports.OPEN_MERGE_FIELDS_MODAL = exports.DISBAND_DRILLDOWN_FAILED = exports.DISBAND_DRILLDOWN_SUCCESS = exports.DISBAND_DRILLDOWN_LOADING = exports.DRILLDOWN_FAILED = exports.DRILLDOWN_SUCCESS = exports.DRILLDOWN_LOADING = exports.WORKSPACE_POST_DRILLDOWN_NODE_FAILED = exports.WORKSPACE_POST_DRILLDOWN_NODE_SUCCESS = exports.WORKSPACE_POST_DRILLDOWN_NODE_LOADING = exports.VIEW_REPORT_ELEMENTS = exports.VIEW_REPORT_ELEMENTS_LOADING = exports.VIEW_REPORT_ELEMENTS_FAILED = exports.SET_CHANGE_INDEX_LOADING = exports.CHANGE_NODE_DIMENSIONS = exports.CHANGE_NODE_DISPLAY_NAME = exports.HANDLE_CONNECT_END = exports.HANDLE_CONNECT_START = exports.HANDLE_DETACH_NODES_FAILED = exports.HANDLE_DETACH_NODES_LOADING = exports.HANDLE_DETACH_NODES_SUCCESS = exports.POST_NODE_DOCUMENT = exports.HANDLE_ATTACH_NODE_FAILED = exports.HANDLE_ATTACH_NODE_LOADING = exports.HANDLE_ATTACH_NODE_SUCCESS = exports.SET_NODE_TO_CUSTOM_SIZE = exports.FIT_SIZE_TO_LABEL_FAILED = exports.FIT_SIZE_TO_LABEL_SUCCESS = exports.FIT_SIZE_TO_LABEL_LOADING = exports.HANDLE_WORKSPACE_TAB_CHANGE_FAILED = exports.HANDLE_WORKSPACE_TAB_CHANGE_LOADING = exports.HANDLE_WORKSPACE_TAB_CHANGE_SUCCESS = exports.HANDLE_WORKSPACE_TAB_CHANGE = exports.HANDLE_WORKSPACE_TAB_LABEL_CHANGE = exports.DELETE_WORKSPACE_TAB_FAILED = exports.DELETE_WORKSPACE_TAB_LOADING = exports.DELETE_WORKSPACE_TAB_SUCCESS = exports.PUT_WORKSPACE_TAB_TITLE_FAILED = void 0;
exports.WORKSPACE_UNDO_DONE = exports.WORKSPACE_UNDO_LOADING = exports.REMOVE_TEMPORARY_CONNECTION = exports.ADD_TEMPORARY_CONNECTION = exports.ALIGN_ELEMENTS_SUCCESS = exports.ALIGN_ELEMENTS_LOADING = exports.ALIGN_ELEMENTS_FAILED = exports.PUT_WORKSPACE_LEGEND_FAILED = exports.PUT_WORKSPACE_LEGEND_SUCCESS = exports.PUT_WORKSPACE_LEGEND_LOADING = exports.GET_WORKSPACE_LEGEND_FAILED = exports.GET_WORKSPACE_LEGEND_SUCCESS = exports.GET_WORKSPACE_LEGEND_LOADING = exports.CLEAR_IDENTIFIED_OWNERS_AND_SUBSIDIARIES = exports.IDENTIFY_OWNERS_AND_SUBSIDIARIES_FAILED = exports.IDENTIFY_OWNERS_AND_SUBSIDIARIES_SUCCESS = exports.IDENTIFY_OWNERS_AND_SUBSIDIARIES_LOADING = exports.IMPORT_FILES_ON_NODE_FAILED = exports.IMPORT_FILES_ON_NODE_SUCCESS = exports.IMPORT_FILES_ON_NODE_LOADING = exports.SET_DEFAULT_RELATIONSHIP_VALUE = exports.SET_DEFAULT_RELATIONSHIP = exports.DUPLICATE_NODE_ON_EDGE_LOADING = exports.DUPLICATE_NODE_ON_EDGE_SUCCESS = exports.CREATE_DUPLICATE_FROM_HANDLE_LOADING = exports.CREATE_DUPLICATE_FROM_HANDLE_SUCCESS = exports.RESET_COMPANY_DATA = exports.SET_WORKSPACE_EDITABLE = exports.TOGGLE_DOCUMENT_MODAL = exports.SET_MUTABLE_GROUP_NODES = exports.SET_IMMUTABLE_GROUP_NODES = exports.UPDATE_CURSOR_ROOM = exports.UPDATE_ROOM = exports.CLIENT_RECONNECTING = exports.CLIENT_LOST_CONNECTION = exports.WS_VALIDATION_ERROR = exports.WS_CONNECTION_ERROR = exports.USER_TOGGLE_ACTIVE = exports.REMOVE_USER_FROM_ELEMENTS = exports.GET_EDITED_ELEMENTS_BY_USERS_FAILED = exports.GET_EDITED_ELEMENTS_BY_USERS_SUCCESS = exports.CLEAR_USER_CURSORS = exports.USER_FINISH_EDIT_EDGES = exports.USER_FINISH_EDIT_NODES = exports.USER_EDIT_EDGES = exports.USER_EDIT_NODES = exports.REMOVE_CONNECTED_USER = exports.ADD_CONNECTED_USERS_CURSOR = exports.USER_UPDATE_POSITION_RELATIVE = exports.USER_UPDATE_POSITION = void 0;
exports.TOGGLE_EASY_CONNECT = exports.CHANGE_NUMBER_OF_HANDLES_FAILED = exports.CHANGE_NUMBER_OF_HANDLES_SUCCESS = exports.CHANGE_NUMBER_OF_HANDLES_LOADING = exports.FRAME_ANALYSIS_SUBSEQUENT_DOWNLOAD = exports.AI_PROMPT_FAILED = exports.AI_PROMPT_SUCCESS = exports.AI_PROMPT_LOADING = exports.SAVE_UNSETTLED_LABEL_SELECTIONS_FAILED = exports.SAVE_UNSETTLED_LABEL_SELECTIONS_SUCCESS = exports.SAVE_UNSETTLED_LABEL_SELECTIONS_LOADING = exports.WORKSPACE_NODE_ADD_DOCUMENTS_FAILED = exports.WORKSPACE_NODE_ADD_DOCUMENTS_LOADING = exports.WORKSPACE_NODE_ADD_DOCUMENTS_SUCCESS = exports.WORKSPACE_SET_FILE_UPLOADS = exports.WORKSPACE_UPDATE_FILE_PROGRESS_UPLOADS = exports.UPLOAD_AI_IMAGE_FAILED = exports.UPLOAD_AI_IMAGE_SUCCESS = exports.UPLOAD_AI_IMAGE_LOADING = exports.WORKSPACE_REDO_DONE = exports.WORKSPACE_REDO_LOADING = void 0;
// API
exports.GET_WORKSPACES_LOADING = "GET_WORKSPACES_LOADING";
exports.GET_WORKSPACES_SUCCESS = "GET_WORKSPACES_SUCCESS";
exports.GET_WORKSPACES_FAILED = "GET_WORKSPACES_FAILED";
exports.GET_RECENT_WORKSPACES_LOADING = "GET_RECENT_WORKSPACES_LOADING";
exports.GET_RECENT_WORKSPACES_SUCCESS = "GET_RECENT_WORKSPACES_SUCCESS";
exports.GET_RECENT_WORKSPACES_FAILED = "GET_RECENT_WORKSPACES_FAILED";
exports.CLEAR_RECENT_WORKSPACES = "CLEAR_RECENT_WORKSPACES";
exports.POST_WORKSPACE_LOADING = "POST_WORKSPACE_LOADING";
exports.POST_WORKSPACE_SUCCESS = "POST_WORKSPACE_SUCCESS";
exports.POST_WORKSPACE_FAILED = "POST_WORKSPACE_FAILED";
exports.SHOW_WORKSPACE_LOADING = "SHOW_WORKSPACE_LOADING";
exports.SHOW_WORKSPACE_SUCCESS = "SHOW_WORKSPACE_SUCCESS";
exports.SHOW_WORKSPACE_FAILED = "SHOW_WORKSPACE_FAILED";
exports.PUT_WORKSPACE_SUCCESS = "PUT_WORKSPACE_SUCCESS";
exports.PUT_WORKSPACE_FAILED = "PUT_WORKSPACE_FAILED";
exports.DELETE_WORKSPACE_SUCCESS = "DELETE_WORKSPACE_SUCCESS";
exports.DELETE_WORKSPACE_FAILED = "DELETE_WORKSPACE_FAILED";
exports.GET_WORKSPACE_ELEMENTS_LOADING = "GET_WORKSPACE_ELEMENTS_LOADING";
exports.GET_WORKSPACE_ELEMENTS_SUCCESS = "GET_WORKSPACE_ELEMENTS_SUCCESS";
exports.GET_WORKSPACE_ELEMENTS_FAILED = "GET_WORKSPACE_ELEMENTS_FAILED";
exports.GET_NODE_ATTRIBUTE_DROPDOWN_SUCCESS = "GET_NODE_ATTRIBUTE_DROPDOWN_SUCCESS";
exports.GET_NODE_ATTRIBUTE_DROPDOWN_FAILED = "GET_NODE_ATTRIBUTE_DROPDOWN_FAILED";
exports.GET_ATTRIBUTE_DROPDOWN_SUCCESS = "GET_ATTRIBUTE_DROPDOWN_SUCCESS";
exports.GET_ATTRIBUTE_DROPDOWN_FAILED = "GET_ATTRIBUTE_DROPDOWN_FAILED";
exports.CLEAR_ATTRIBUTE_DROPDOWN = "CLEAR_ATTRIBUTE_DROPDOWN";
exports.GET_RELATIONSHIP_VALUES_SUCCESS = "GET_RELATIONSHIP_VALUES_SUCCESS";
exports.GET_RELATIONSHIP_VALUES_FAILED = "GET_RELATIONSHIP_VALUES_FAILED";
exports.GET_NODE_VALUES_SUCCESS = "GET_NODE_VALUES_SUCCESS";
exports.GET_NODE_VALUES_FAILED = "GET_NODE_VALUES_FAILED";
exports.PUT_Z_INDEX_TO_FRONT_LOADING = "PUT_Z_INDEX_TO_FRONT_LOADING";
exports.PUT_Z_INDEX_TO_FRONT_SUCCESS = "PUT_Z_INDEX_TO_FRONT_SUCCESS";
exports.PUT_Z_INDEX_TO_FRONT_FAILED = "PUT_Z_INDEX_TO_FRONT_FAILED";
exports.PUT_Z_INDEX_TO_BACK_LOADING = "PUT_Z_INDEX_TO_BACK_LOADING";
exports.PUT_Z_INDEX_TO_BACK_SUCCESS = "PUT_Z_INDEX_TO_BACK_SUCCESS";
exports.PUT_Z_INDEX_TO_BACK_FAILED = "PUT_Z_INDEX_TO_BACK_FAILED";
exports.PUT_Z_INDEX_FORWARD_LOADING = "PUT_Z_INDEX_FORWARD_LOADING";
exports.PUT_Z_INDEX_FORWARD_SUCCESS = "PUT_Z_INDEX_FORWARD_SUCCESS";
exports.PUT_Z_INDEX_FORWARD_FAILED = "PUT_Z_INDEX_FORWARD_FAILED";
exports.PUT_Z_INDEX_BACKWARD_LOADING = "PUT_Z_INDEX_BACKWARD_LOADING";
exports.PUT_Z_INDEX_BACKWARD_SUCCESS = "PUT_Z_INDEX_BACKWARD_SUCCESS";
exports.PUT_Z_INDEX_BACKWARD_FAILED = "PUT_Z_INDEX_BACKWARD_FAILED";
exports.DELETE_WORKSPACE_ELEMENTS_SUCCESS = "DELETE_WORKSPACE_ELEMENTS_SUCCESS";
exports.DELETE_WORKSPACE_ELEMENTS_FAILED = "DELETE_WORKSPACE_ELEMENTS_FAILED";
exports.WORKSPACE_ADD_ELEMENTS_LOADING = "WORKSPACE_ADD_ELEMENTS_LOADING";
exports.WORKSPACE_ADD_ELEMENTS_SUCCESS = "WORKSPACE_ADD_ELEMENTS_SUCCESS";
exports.WORKSPACE_ADD_ELEMENTS_FAILED = "WORKSPACE_ADD_ELEMENTS_FAILED";
exports.WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_LOADING = "WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_LOADING";
exports.WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_SUCCESS = "WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_SUCCESS";
exports.WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_FAILED = "WORKSPACE_CHANGE_CUSTOM_RULES_APPLIED_FAILED";
exports.GET_CVR_NODES_LOADING = "GET_CVR_NODES_LOADING";
exports.GET_CVR_NODES_SUCCESS = "GET_CVR_NODES_SUCCESS";
exports.GET_CVR_NODES_FAILED = "GET_CVR_NODES_FAILED";
exports.IMPORT_STRUCTURE_LOADING = "IMPORT_STRUCTURE_LOADING";
exports.IMPORT_STRUCTURE_SUCCESS = "IMPORT_STRUCTURE_SUCCESS";
exports.IMPORT_STRUCTURE_FAILED = "IMPORT_STRUCTURE_FAILED";
exports.NEW_BUILDING_BLOCKS_AND_TABS = "NEW_BUILDING_BLOCKS_AND_TABS";
exports.GET_WORKSPACE_NODE_COMPANY_DATA_LOADING = "GET_WORKSPACE_NODE_COMPANY_DATA_LOADING";
exports.GET_WORKSPACE_NODE_COMPANY_DATA_SUCCESS = "GET_WORKSPACE_NODE_COMPANY_DATA_SUCCESS";
exports.GET_WORKSPACE_NODE_COMPANY_DATA_FAILED = "GET_WORKSPACE_NODE_COMPANY_DATA_FAILED";
exports.GET_WORKSPACE_NODE_ADDRESS_INFO_LOADING = "GET_WORKSPACE_NODE_ADDRESS_INFO_LOADING";
exports.GET_WORKSPACE_NODE_ADDRESS_INFO_SUCCESS = "GET_WORKSPACE_NODE_ADDRESS_INFO_SUCCESS";
exports.GET_WORKSPACE_NODE_ADDRESS_INFO_FAILED = "GET_WORKSPACE_NODE_ADDRESS_INFO_FAILED";
exports.ANALYSE_OUTPUT_LOADING = "ANALYSE_OUTPUT_LOADING";
exports.ANALYSE_OUTPUT_SUCCESS = "ANALYSE_OUTPUT_SUCCESS";
exports.ANALYSE_OUTPUT_FAILED = "ANALYSE_OUTPUT_FAILED";
exports.SAVE_WORKSPACE_SUCCESS = "SAVE_WORKSPACE_SUCCESS";
exports.SAVE_WORKSPACE_FAILED = "SAVE_WORKSPACE_FAILED";
exports.DELETE_WORKSPACE_NODES_LOADING = "DELETE_WORKSPACE_NODES_LOADING";
exports.DELETE_WORKSPACE_NODES_SUCCESS = "DELETE_WORKSPACE_NODES_SUCCESS";
exports.DELETE_WORKSPACE_NODES_FAILED = "DELETE_WORKSPACE_NODES_FAILED";
exports.DELETE_WORKSPACE_EDGES_LOADING = "DELETE_WORKSPACE_EDGES_LOADING";
exports.DELETE_WORKSPACE_EDGES_SUCCESS = "DELETE_WORKSPACE_EDGES_SUCCESS";
exports.DELETE_WORKSPACE_EDGES_FAILED = "DELETE_WORKSPACE_EDGES_FAILED";
exports.SHARE_WORKSPACE_SUCCESS = "SHARE_WORKSPACE_SUCCESS";
exports.SHARE_WORKSPACE_LOADING = "SHARE_WORKSPACE_LOADING";
exports.SHARE_WORKSPACE_FAILED = "SHARE_WORKSPACE_FAILED";
exports.PUBLIC_ACCESS_WORKSPACE_LOADING = "PUBLIC_ACCESS_WORKSPACE_LOADING";
exports.PUBLIC_ACCESS_WORKSPACE_SUCCESS = "PUBLIC_ACCESS_WORKSPACE_SUCCESS";
exports.PUBLIC_ACCESS_WORKSPACE_FAILED = "PUBLIC_ACCESS_WORKSPACE_FAILED";
exports.IMPORT_WORKSPACE_FILES_LOADING = "IMPORT_WORKSPACE_FILES_LOADING";
exports.IMPORT_WORKSPACE_FILES_SUCCESS = "IMPORT_WORKSPACE_FILES_SUCCESS";
exports.IMPORT_WORKSPACE_FILES_FAILED = "IMPORT_WORKSPACE_FILES_FAILED";
exports.SET_PUBLIC_ACCESS_FALSE = "SET_PUBLIC_ACCESS_FALSE";
exports.POST_DROPDOWN_VALUE_SUCCESS = "WORKSPACE_POST_DROPDOWN_VALUE_SUCCESS";
exports.POST_DROPDOWN_VALUE_FAILED = "WORKSPACE_POST_DROPDOWN_VALUE_FAILED";
exports.PUT_SELECTED_ATTRIBUTE_OPTION_SUCCESS = "WORKSPACE_PUT_SELECTED_ATTRIBUTE_OPTION_SUCCESS";
exports.PUT_SELECTED_ATTRIBUTE_OPTION_FAILED = "WORKSPACE_PUT_SELECTED_ATTRIBUTE_OPTION_FAILED";
exports.POST_STANDARD_NODE_LOADING = "WORKSPACE_POST_STANDARD_NODE_LOADING";
exports.POST_STANDARD_NODE_SUCCESS = "WORKSPACE_POST_STANDARD_NODE_SUCCESS";
exports.POST_STANDARD_NODE_FAILED = "WORKSPACE_POST_STANDARD_NODE_FAILED";
// Workspcae Meta
exports.LABEL_CHANGE = "WORKSPACE_LABEL_CHANGE";
exports.DESCRIPTION_CHANGE = "WORKSPACE_DESCRIPTION_CHANGE";
exports.ADD_LAWSUIT = "WORKSPACE_ADD_LAWSUIT";
exports.CHANGE_TAGS = "WORKSPACE_CHANGE_TAGS";
exports.SHARE_ORG_CHANGE = "WORKSPACE_SHARE_ORG_CHANGE";
exports.SET_IS_WORKSPACE_PUBLIC = "WORKSPACE_SET_IS_WORKSPACE_PUBLIC";
// Edges
exports.EDGE_ADD_TO_LIST = "EDGE_ADD_TO_LIST";
exports.POST_EDGE_LOADING = "POST_EDGE_LOADING";
exports.POST_EDGE_SUCCESS = "POST_EDGE_SUCCESS";
exports.POST_EDGE_FAILED = "POST_EDGE_FAILED";
exports.PUT_EDGE_LOADING = "PUT_EDGE_LOADING";
exports.PUT_EDGE_SUCCESS = "PUT_EDGE_SUCCESS";
exports.PUT_EDGE_FAILED = "PUT_EDGE_FAILED";
exports.MAKE_STEP_FRAMES_ON_TAB_SUCCESS = "MAKE_STEP_FRAMES_ON_TAB_SUCCESS";
exports.MAKE_STEP_FRAMES_ON_TAB_FAILED = "MAKE_STEP_FRAMES_ON_TAB_FAILED";
exports.PUT_EDGE_POSITION_LOADING = "PUT_EDGE_POSITION_LOADING";
exports.PUT_EDGE_POSITION_SUCCESS = "PUT_EDGE_POSITION_SUCCESS";
exports.PUT_EDGE_POSITION_FAILED = "PUT_EDGE_POSITION_FAILED";
exports.EDGE_LABEL_MOVE_LOADING = "EDGE_LABEL_MOVE_LOADING";
exports.EDGE_LABEL_MOVE_SUCCESS = "EDGE_LABEL_MOVE_SUCCESS";
exports.EDGE_LABEL_MOVE_FAILED = "EDGE_LABEL_MOVE_FAILED";
exports.EDGE_PATH_DRAG_LOADING = "EDGE_PATH_DRAG_LOADING";
exports.EDGE_PATH_DRAG_SUCCESS = "EDGE_PATH_DRAG_SUCCESS";
exports.EDGE_PATH_DRAG_FAILED = "EDGE_PATH_DRAG_FAILED";
// nodes
exports.WORKSPACE_POST_NODE_LOADING = "WORKSPACE_POST_NODE_LOADING";
exports.WORKSPACE_POST_NODE_SUCCESS = "WORKSPACE_POST_NODE_SUCCESS";
exports.WORKSPACE_POST_NODE_FAILED = "WORKSPACE_POST_NODE_FAILED";
exports.WORKSPACE_PUT_NODE_LOADING = "WORKSPACE_PUT_NODE_LOADING";
exports.WORKSPACE_PUT_NODE_SUCCESS = "WORKSPACE_PUT_NODE_SUCCESS";
exports.WORKSPACE_PUT_NODE_FAILED = "WORKSPACE_PUT_NODE_FAILED";
exports.WORKSPACE_PUT_MULTIPLE_NODES_LOADING = "WORKSPACE_PUT_MULTIPLE_NODES_LOADING";
exports.WORKSPACE_PUT_MULTIPLE_NODES_SUCCESS = "WORKSPACE_PUT_MULTIPLE_NODES_SUCCESS";
exports.WORKSPACE_PUT_MULTIPLE_NODES_FAILED = "WORKSPACE_PUT_MULTIPLE_NODES_FAILED";
exports.WORKSPACE_NODE_ADD_TO_LIST = "WORKSPACE_NODE_ADD_TO_LIST";
exports.WORKSPACE_NODE_ATTRIBUT_ADD_TO_LIST = "WORKSPACE_NODE_ATTRIBUT_ADD_TO_LIST";
exports.WORKSPACE_PUT_NODE_COLOR_SUCCESS = "WORKSPACE_PUT_NODE_COLOR_SUCCESS";
exports.WORKSPACE_POST_FILE_LOADING = "WORKSPACE_POST_FILE_LOADING";
exports.WORKSPACE_POST_FILE_SUCCESS = "WORKSPACE_POST_FILE_SUCCESS";
exports.WORKSPACE_POST_FILE_FAILED = "WORKSPACE_POST_FILE_FAILED";
exports.WORKSPACE_POST_ICON_LOADING = "WORKSPACE_POST_ICON_LOADING";
exports.WORKSPACE_POST_ICON_SUCCESS = "WORKSPACE_POST_ICON_SUCCESS";
exports.WORKSPACE_POST_ICON_FAILED = "WORKSPACE_POST_ICON_FAILED";
// anlysis
exports.WORKSPACE_ANALYSIS_SAVE_SUCCESS = "WORKSPACE_ANALYSIS_SAVE_SUCCESS";
exports.WORKSPACE_ANALYSIS_SAVE_FAILED = "WORKSPACE_ANALYSIS_SAVE_FAILED";
exports.WORKSPACE_ANALYSIS_REVISION_SUCCESS = "WORKSPACE_ANALYSIS_REVISION_SUCCESS";
exports.WORKSPACE_ANALYSIS_REVISION_FAILED = "WORKSPACE_ANALYSIS_REVISION_FAILED";
// CONTROL
exports.SHOW_HANDLES_CHANGE = "SHOW_HANDLES_CHANGE";
exports.LAYOUT_ELEMENTS = "LAYOUT_ELEMENTS";
exports.ANALYSIS_TEXT_CHANGE = "ANALYSIS_TEXT_CHANGE";
exports.SET_PUBLIC_VISITED = "SET_PUBLIC_VISITED";
exports.SET_SHOW_ADDRESS_INFO = "SET_SHOW_ADDRESS_INFO";
exports.STOP_LOADING = "STOP_LOADING";
exports.DO_NOT_SHOW_INTERNATIONAL_DISCLAIMER_AGAIN = "DO_NOT_SHOW_INTERNATIONAL_DISCLAIMER_AGAIN";
exports.CHANGE_NODES = "CHANGE_NODES";
exports.CHANGE_EDGES = "CHANGE_EDGES";
// UNDO REDO Calls
exports.WORKSPACE_UNDO = "WORKSPACE_UNDO";
exports.WORKSPACE_REDO = "WORKSPACE_REDO";
// For WS Purposes:
exports.WORKSPACE_UNDO_PAYLOAD = "WORKSPACE_UNDO_PAYLOAD";
exports.WORKSPACE_REDO_PAYLOAD = "WORKSPACE_REDO_PAYLOAD";
// UNDO REDO Utility
exports.WORKSPACE_UNDO_RESET = "WORKSPACE_UNDO_RESET";
exports.WORKSPACE_REDO_RESET = "WORKSPACE_REDO_RESET";
exports.WORKSPACE_UNDO_APPEND = "WORKSPACE_UNDO_APPEND";
exports.OPEN_INFO_MODAL = "OPEN_INFO_MODAL";
exports.CLOSE_INFO_MODAL = "CLOSE_INFO_MODAL";
exports.CLEAR_STATE = "WORKSPACE_CLEAR_STATE";
exports.CLEAR_UNSETTLED_LABELS = "CLEAR_UNSETTLED_LABELS";
// Soft delete workspace nodes
exports.SOFT_DELETE_WORKSPACE_NODES_LOADING = "SOFT_DELETE_WORKSPACE_NODES_LOADING";
exports.SOFT_DELETE_WORKSPACE_NODES_SUCCESS = "SOFT_DELETE_WORKSPACE_NODES_SUCCESS";
exports.SOFT_DELETE_WORKSPACE_NODES_FAILED = "SOFT_DELETE_WORKSPACE_NODES_FAILED";
// Soft delete workspace edges
exports.SOFT_DELETE_WORKSPACE_EDGES_LOADING = "SOFT_DELETE_WORKSPACE_EDGES_LOADING";
exports.SOFT_DELETE_WORKSPACE_EDGES_SUCCESS = "SOFT_DELETE_WORKSPACE_EDGES_SUCCESS";
exports.SOFT_DELETE_WORKSPACE_EDGES_FAILED = "SOFT_DELETE_WORKSPACE_EDGES_FAILED";
exports.WORKSPACE_PUT_STICKY_LOADING = "WORKSPACE_PUT_STICKY_LOADING";
exports.WORKSPACE_PUT_STICKY_SUCCESS = "WORKSPACE_PUT_STICKY_SUCCESS";
exports.WORKSPACE_PUT_STICKY_FAILED = "WORKSPACE_PUT_STICKY_FAILED";
exports.SAVE_NODE_POSITION_LOADING = "SAVE_NODE_POSITION_LOADING";
exports.SAVE_NODE_POSITION_SUCCESS = "SAVE_NODE_POSITION_SUCCESS";
exports.SAVE_NODE_POSITION_FAILED = "SAVE_NODE_POSITION_FAILED";
exports.SAVE_NODE_SIZE_LOADING = "SAVE_NODE_SIZE_LOADING";
exports.SAVE_NODE_SIZE_SUCCESS = "SAVE_NODE_SIZE_SUCCESS";
exports.SAVE_NODE_SIZE_FAILED = "SAVE_NODE_SIZE_FAILED";
exports.GET_WORKSPACE_MERGE_FIELDS_LOADING = "GET_WORKSPACE_MERGE_FIELDS_LOADING";
exports.GET_WORKSPACE_MERGE_FIELDS_SUCCESS = "GET_WORKSPACE_MERGE_FIELDS_SUCCESS";
exports.GET_WORKSPACE_MERGE_FIELDS_FAILED = "GET_WORKSPACE_MERGE_FIELDS_FAILED";
exports.PUT_WORKSPACE_MERGE_FIELDS_LOADING = "PUT_WORKSPACE_MERGE_FIELDS_LOADING";
exports.PUT_WORKSPACE_MERGE_FIELDS_SUCCESS = "PUT_WORKSPACE_MERGE_FIELDS_SUCCESS";
exports.PUT_WORKSPACE_MERGE_FIELDS_FAILED = "PUT_WORKSPACE_MERGE_FIELDS_FAILED";
exports.CHANGE_MERGE_FIELD = "CHANGE_MERGE_FIELD";
exports.CREATE_WORKSPACE = "CREATE_WORKSPACE";
exports.HARD_DELETE_WORKSPACE_ELEMENTS_LOADING = "HARD_DELETE_WORKSPACE_ELEMENTS_LOADING";
exports.HARD_DELETE_WORKSPACE_ELEMENTS_FAILED = "HARD_DELETE_WORKSPACE_ELEMENTS_FAILED";
exports.HARD_DELETE_WORKSPACE_ELEMENTS_SUCCESS = "HARD_DELETE_WORKSPACE_ELEMENTS_SUCCESS";
exports.TOGGLE_NODE_STICKY_UNDO_EVENT = "TOGGLE_NODE_STICKY_UNDO_EVENT";
exports.TOGGLE_FRAME_PANEL = "TOGGLE_FRAME_PANEL";
exports.OPEN_FRAME_PANEL = "OPEN_FRAME_PANEL";
exports.CLOSE_FRAME_PANEL = "CLOSE_FRAME_PANEL";
exports.TOGGLE_REPORT_PANEL = "TOGGLE_REPORT_PANEL";
exports.OPEN_REPORT_PANEL = "OPEN_REPORT_PANEL";
exports.CLOSE_REPORT_PANEL = "CLOSE_REPORT_PANEL";
exports.TOGGLE_ALERT_PANEL = "TOGGLE_ALERT_PANEL";
exports.OPEN_ALERT_PANEL = "OPEN_ALERT_PANEL";
exports.CLOSE_ALERT_PANEL = "CLOSE_ALERT_PANEL";
exports.GET_WORKSPACE_TABS_SUCCESS = "GET_WORKSPACE_TABS_SUCCESS";
exports.GET_WORKSPACE_TABS_LOADING = "GET_WORKSPACE_TABS_LOADING";
exports.GET_WORKSPACE_TABS_FAILED = "GET_WORKSPACE_TABS_FAILED";
exports.POST_WORKSPACE_TAB_SUCCESS = "POST_WORKSPACE_TAB_SUCCESS";
exports.POST_WORKSPACE_TAB_LOADING = "POST_WORKSPACE_TAB_LOADING";
exports.POST_WORKSPACE_TAB_FAILED = "POST_WORKSPACE_TAB_FAILED";
exports.PUT_WORKSPACE_TAB_TITLE_SUCCESS = "PUT_WORKSPACE_TAB_TITLE_SUCCESS";
exports.PUT_WORKSPACE_TAB_TITLE_LOADING = "PUT_WORKSPACE_TAB_TITLE_LOADING";
exports.PUT_WORKSPACE_TAB_TITLE_FAILED = "PUT_WORKSPACE_TAB_TITLE_FAILED";
exports.DELETE_WORKSPACE_TAB_SUCCESS = "DELETE_WORKSPACE_TAB_SUCCESS";
exports.DELETE_WORKSPACE_TAB_LOADING = "DELETE_WORKSPACE_TAB_LOADING";
exports.DELETE_WORKSPACE_TAB_FAILED = "DELETE_WORKSPACE_TAB_FAILED";
exports.HANDLE_WORKSPACE_TAB_LABEL_CHANGE = "HANDLE_WORKSPACE_TAB_LABEL_CHANGE";
exports.HANDLE_WORKSPACE_TAB_CHANGE = "HANDLE_WORKSPACE_TAB_CHANGE";
exports.HANDLE_WORKSPACE_TAB_CHANGE_SUCCESS = "HANDLE_WORKSPACE_TAB_CHANGE_SUCCESS";
exports.HANDLE_WORKSPACE_TAB_CHANGE_LOADING = "HANDLE_WORKSPACE_TAB_CHANGE_LOADING";
exports.HANDLE_WORKSPACE_TAB_CHANGE_FAILED = "HANDLE_WORKSPACE_TAB_CHANGE_FAILED";
exports.FIT_SIZE_TO_LABEL_LOADING = "FIT_SIZE_TO_LABEL_LOADING";
exports.FIT_SIZE_TO_LABEL_SUCCESS = "FIT_SIZE_TO_LABEL_SUCCESS";
exports.FIT_SIZE_TO_LABEL_FAILED = "FIT_SIZE_TO_LABEL_FAILED";
exports.SET_NODE_TO_CUSTOM_SIZE = "WORKSPACE_SET_NODE_TO_CUSTOM_SIZE";
exports.HANDLE_ATTACH_NODE_SUCCESS = "HANDLE_ATTACH_NODE_SUCCESS";
exports.HANDLE_ATTACH_NODE_LOADING = "HANDLE_ATTACH_NODE_LOADING";
exports.HANDLE_ATTACH_NODE_FAILED = "HANDLE_ATTACH_NODE_FAILED";
exports.POST_NODE_DOCUMENT = "POST_NODE_DOCUMENT";
exports.HANDLE_DETACH_NODES_SUCCESS = "HANDLE_DETACH_NODES_SUCCESS";
exports.HANDLE_DETACH_NODES_LOADING = "HANDLE_DETACH_NODES_LOADING";
exports.HANDLE_DETACH_NODES_FAILED = "HANDLE_DETACH_NODES_FAILED";
exports.HANDLE_CONNECT_START = "HANDLE_CONNECT_START";
exports.HANDLE_CONNECT_END = "HANDLE_CONNECT_END";
exports.CHANGE_NODE_DISPLAY_NAME = "CHANGE_NODE_DISPLAY_NAME";
exports.CHANGE_NODE_DIMENSIONS = "CHANGE_NODE_DIMENSIONS";
exports.SET_CHANGE_INDEX_LOADING = "SET_CHANGE_INDEX_LOADING";
exports.VIEW_REPORT_ELEMENTS_FAILED = "VIEW_REPORT_ELEMENTS_FAILED";
exports.VIEW_REPORT_ELEMENTS_LOADING = "VIEW_REPORT_ELEMENTS_LOADING";
exports.VIEW_REPORT_ELEMENTS = "VIEW_REPORT_ELEMENTS";
// DRILLDOWN
exports.WORKSPACE_POST_DRILLDOWN_NODE_LOADING = "WORKSPACE_POST_DRILLDOWN_NODE_LOADING";
exports.WORKSPACE_POST_DRILLDOWN_NODE_SUCCESS = "WORKSPACE_POST_DRILLDOWN_NODE_SUCCESS";
exports.WORKSPACE_POST_DRILLDOWN_NODE_FAILED = "WORKSPACE_POST_DRILLDOWN_NODE_FAILED";
exports.DRILLDOWN_LOADING = "DRILLDOWN_LOADING";
exports.DRILLDOWN_SUCCESS = "DRILLDOWN_SUCCESS";
exports.DRILLDOWN_FAILED = "DRILLDOWN_FAILED";
exports.DISBAND_DRILLDOWN_LOADING = "DISBAND_DRILLDOWN_LOADING";
exports.DISBAND_DRILLDOWN_SUCCESS = "DISBAND_DRILLDOWN_SUCCESS";
exports.DISBAND_DRILLDOWN_FAILED = "DISBAND_DRILLDOWN_FAILED";
// !DRILLDOWN
exports.OPEN_MERGE_FIELDS_MODAL = "OPEN_MERGE_FIELDS_MODAL";
exports.CLOSE_MERGE_FIELDS_MODAL = "CLOSE_MERGE_FIELDS_MODAL";
exports.WORKSPACE_DESELECT_EDGES = "WORKSPACE_DESELECT_EDGES";
exports.WORKSPACE_DUPLICATE_LOADING = "WORKSPACE_DUPLICATE_LOADING";
exports.WORKSPACE_DUPLICATE_SUCCESS = "WORKSPACE_DUPLICATE_SUCCESS";
exports.WORKSPACE_DUPLICATE_FAILED = "WORKSPACE_DUPLICATE_FAILED";
exports.WORKSPACE_CHANGE_POWERPOINT_HELPER_SIZE = "WORKSPACE_CHANGE_POWERPOINT_HELPER_SIZE";
exports.WORKSPACE_NODE_TOGGLE_EXIT_LEVEL_PWC = "WORKSPACE_NODE_TOGGLE_EXIT_LEVEL_PWC";
exports.WORKSPACE_NODE_TOGGLE_EXIT_LEVEL_PWC_FAILED = "WORKSPACE_NODE_TOGGLE_EXIT_LEVEL_PWC_FAILED";
exports.REMOVE_WORKSPACE_TAB_FOR_WORKSPACE = "REMOVE_WORKSPACE_TAB_FOR_WORKSPACE";
exports.POST_WORKSPACE_TAB_FOR_WORKSPACE = "POST_WORKSPACE_TAB_FOR_WORKSPACE";
exports.PUT_WORKSPACE_TAB_TITLE_FOR_WORKSPACE = "PUT_WORKSPACE_TAB_TITLE_FOR_WORKSPACE";
exports.USER_JOIN_ROOM = "USER_JOIN_ROOM";
exports.USER_UPDATE_POSITION = "USER_UPDATE_POSITION";
exports.USER_UPDATE_POSITION_RELATIVE = "USER_UPDATE_POSITION_RELATIVE";
exports.ADD_CONNECTED_USERS_CURSOR = "ADD_CONNECTED_USERS_CURSOR";
exports.REMOVE_CONNECTED_USER = "REMOVE_CONNECTED_USER";
exports.USER_EDIT_NODES = "USER_EDIT_NODES";
exports.USER_EDIT_EDGES = "USER_EDIT_EDGES";
exports.USER_FINISH_EDIT_NODES = "USER_FINISH_EDIT_NODES";
exports.USER_FINISH_EDIT_EDGES = "USER_FINISH_EDIT_EDGES";
exports.CLEAR_USER_CURSORS = "CLEAR_USER_CURSORS";
exports.GET_EDITED_ELEMENTS_BY_USERS_SUCCESS = "GET_EDITED_ELEMENTS_BY_USERS_SUCCESS";
exports.GET_EDITED_ELEMENTS_BY_USERS_FAILED = "GET_EDITED_ELEMENTS_BY_USERS_FAILED";
exports.REMOVE_USER_FROM_ELEMENTS = "REMOVE_USER_FROM_ELEMENTS";
exports.USER_TOGGLE_ACTIVE = "USER_TOGGLE_ACTIVE";
exports.WS_CONNECTION_ERROR = "WS_CONNECTION_ERROR";
exports.WS_VALIDATION_ERROR = "WS_VALIDATION_ERROR";
exports.CLIENT_LOST_CONNECTION = "CLIENT_LOST_CONNECTION";
exports.CLIENT_RECONNECTING = "CLIENT_RECONNECTING";
exports.UPDATE_ROOM = "UPDATE_ROOM";
exports.UPDATE_CURSOR_ROOM = "UPDATE_CURSOR_ROOM";
exports.SET_IMMUTABLE_GROUP_NODES = "IMMUTABLE_GROUP_NODES";
exports.SET_MUTABLE_GROUP_NODES = "MUTABLE_GROUP_NODES";
exports.TOGGLE_DOCUMENT_MODAL = "WORKSPACE_TOGGLE_DOCUMENT_MODAL";
exports.SET_WORKSPACE_EDITABLE = "SET_WORKSPACE_EDITABLE";
exports.RESET_COMPANY_DATA = "RESET_COMPANY_DATA";
exports.CREATE_DUPLICATE_FROM_HANDLE_SUCCESS = "CREATE_DUPLICATE_FROM_HANDLE_SUCCESS";
exports.CREATE_DUPLICATE_FROM_HANDLE_LOADING = "CREATE_DUPLICATE_FROM_HANDLE_LOADING";
exports.DUPLICATE_NODE_ON_EDGE_SUCCESS = "DUPLICATE_NODE_ON_EDGE_SUCCESS";
exports.DUPLICATE_NODE_ON_EDGE_LOADING = "DUPLICATE_NODE_ON_EDGE_LOADING";
exports.SET_DEFAULT_RELATIONSHIP = "SET_DEFAULT_RELATIONSHIP";
exports.SET_DEFAULT_RELATIONSHIP_VALUE = "SET_DEFAULT_RELATIONSHIP_VALUE";
exports.IMPORT_FILES_ON_NODE_LOADING = "IMPORT_FILES_ON_NODE_LOADING";
exports.IMPORT_FILES_ON_NODE_SUCCESS = "IMPORT_FILES_ON_NODE_SUCCESS";
exports.IMPORT_FILES_ON_NODE_FAILED = "IMPORT_FILES_ON_NODE_FAILED";
exports.IDENTIFY_OWNERS_AND_SUBSIDIARIES_LOADING = "IDENTIFY_OWNERS_AND_SUBSIDIARIES_LOADING";
exports.IDENTIFY_OWNERS_AND_SUBSIDIARIES_SUCCESS = "IDENTIFY_OWNERS_AND_SUBSIDIARIES_SUCCESS";
exports.IDENTIFY_OWNERS_AND_SUBSIDIARIES_FAILED = "IDENTIFY_OWNERS_AND_SUBSIDIARIES_FAILED";
exports.CLEAR_IDENTIFIED_OWNERS_AND_SUBSIDIARIES = "CLEAR_IDENTIFIED_OWNERS_AND_SUBSIDIARIES";
exports.GET_WORKSPACE_LEGEND_LOADING = "GET_WORKSPACE_LEGEND_LOADING";
exports.GET_WORKSPACE_LEGEND_SUCCESS = "GET_WORKSPACE_LEGEND_SUCCESS";
exports.GET_WORKSPACE_LEGEND_FAILED = "GET_WORKSPACE_LEGEND_FAILED";
exports.PUT_WORKSPACE_LEGEND_LOADING = "PUT_WORKSPACE_LEGEND_LOADING";
exports.PUT_WORKSPACE_LEGEND_SUCCESS = "PUT_WORKSPACE_LEGEND_SUCCESS";
exports.PUT_WORKSPACE_LEGEND_FAILED = "PUT_WORKSPACE_LEGEND_FAILED";
exports.ALIGN_ELEMENTS_FAILED = "ALIGN_ELEMENTS_FAILED";
exports.ALIGN_ELEMENTS_LOADING = "ALIGN_ELEMENTS_LOADING";
exports.ALIGN_ELEMENTS_SUCCESS = "ALIGN_ELEMENTS_SUCCESS";
exports.ADD_TEMPORARY_CONNECTION = "ADD_TEMPORARY_CONNECTION";
exports.REMOVE_TEMPORARY_CONNECTION = "REMOVE_TEMPORARY_CONNECTION";
exports.WORKSPACE_UNDO_LOADING = "WORKSPACE_UNDO_LOADING";
exports.WORKSPACE_UNDO_DONE = "WORKSPACE_UNDO_DONE";
exports.WORKSPACE_REDO_LOADING = "WORKSPACE_REDO_LOADING";
exports.WORKSPACE_REDO_DONE = "WORKSPACE_REDO_DONE";
exports.UPLOAD_AI_IMAGE_LOADING = "UPLOAD_AI_IMAGE_LOADING";
exports.UPLOAD_AI_IMAGE_SUCCESS = "UPLOAD_AI_IMAGE_SUCCESS";
exports.UPLOAD_AI_IMAGE_FAILED = "UPLOAD_AI_IMAGE_FAILED";
exports.WORKSPACE_UPDATE_FILE_PROGRESS_UPLOADS = "WORKSPACE_UPDATE_FILE_PROGRESS_UPLOADS";
exports.WORKSPACE_SET_FILE_UPLOADS = "WORKSPACE_SET_FILE_UPLOADS";
exports.WORKSPACE_NODE_ADD_DOCUMENTS_SUCCESS = "WORKSPACE_NODE_ADD_DOCUMENTS_SUCCESS";
exports.WORKSPACE_NODE_ADD_DOCUMENTS_LOADING = "WORKSPACE_NODE_ADD_DOCUMENTS_LOADING";
exports.WORKSPACE_NODE_ADD_DOCUMENTS_FAILED = "WORKSPACE_NODE_ADD_DOCUMENTS_FAILED";
exports.SAVE_UNSETTLED_LABEL_SELECTIONS_LOADING = "SAVE_UNSETTLED_LABEL_SELECTIONS_LOADING";
exports.SAVE_UNSETTLED_LABEL_SELECTIONS_SUCCESS = "SAVE_UNSETTLED_LABEL_SELECTIONS_SUCCESS";
exports.SAVE_UNSETTLED_LABEL_SELECTIONS_FAILED = "SAVE_UNSETTLED_LABEL_SELECTIONS_FAILED";
exports.AI_PROMPT_LOADING = "AI_PROMPT_LOADING";
exports.AI_PROMPT_SUCCESS = "AI_PROMPT_SUCCESS";
exports.AI_PROMPT_FAILED = "AI_PROMPT_FAILED";
exports.FRAME_ANALYSIS_SUBSEQUENT_DOWNLOAD = "FRAME_ANALYSIS_SUBSEQUENT_DOWNLOAD";
exports.CHANGE_NUMBER_OF_HANDLES_LOADING = "CHANGE_NUMBER_OF_HANDLES_LOADING";
exports.CHANGE_NUMBER_OF_HANDLES_SUCCESS = "CHANGE_NUMBER_OF_HANDLES_SUCCESS";
exports.CHANGE_NUMBER_OF_HANDLES_FAILED = "CHANGE_NUMBER_OF_HANDLES_FAILED";
exports.TOGGLE_EASY_CONNECT = "TOGGLE_EASY_CONNECT";
