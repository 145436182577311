"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAiClassName = exports.getBorderStyle = void 0;
var NodeSVGPath_1 = require("../Shapes/utilities/NodeSVGPath");
function getBorderStyle(type, figure) {
    if (!figure) {
        return type === "AI" ? undefined : type;
    }
    return type === "AI" || (0, NodeSVGPath_1.isSvgIcon)(figure) ? undefined : type;
}
exports.getBorderStyle = getBorderStyle;
function getAiClassName(type, figure) {
    if (!figure) {
        return {
            "ai-rainbow-border_animated": false, // type === "AI",
        };
    }
    return {
        "ai-rainbow-border_animated": false, // type === "AI" && !isSvgIcon(figure),
    };
}
exports.getAiClassName = getAiClassName;
