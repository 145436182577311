"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.gradientBgLight = void 0;
var mui_1 = require("tss-react/mui");
var gradientBgLight = function (theme) {
    return "linear-gradient(-45deg, ".concat(theme.palette.background.default, " 0%, ").concat(theme.palette.background.default, " 33%, ").concat(theme.palette.secondary.main, " 100%);");
};
exports.gradientBgLight = gradientBgLight;
var appFrame = {
    display: "flex",
    width: "100%",
    minHeight: "100%",
    zIndex: 1,
};
exports.default = (0, mui_1.makeStyles)()(function (theme, _params, classes) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return ({
        root: {
            width: "100%",
            marginTop: 0,
            zIndex: 1,
            overflow: "auto",
        },
        blogWrap: {
            color: theme.palette.text.primary,
            position: "relative",
        },
        appFrameInner: __assign({ color: theme.palette.text.primary }, appFrame),
        appFrameOuter: __assign({ color: theme.palette.text.primary }, appFrame),
        appFrameLanding: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
            minHeight: 1000,
        },
        appFrameSlider: (_a = {
                display: "flex",
                width: "100%",
                height: "100%",
                color: theme.palette.text.primary
            },
            _a[theme.breakpoints.up("lg")] = {
                position: "absolute",
                overflow: "hidden",
            },
            _a.backgroundColor = theme.palette.background.default,
            _a),
        topNav: {
            flexDirection: "column",
        },
        sideNav: {
            flexDirection: "row",
        },
        content: (_b = {
                width: "100%",
                padding: theme.spacing(2),
                minHeight: "100%",
                overflow: "hidden"
            },
            _b[theme.breakpoints.down("lg")] = {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
            _b),
        contentPaddingNone: {
            padding: 0,
        },
        outerContent: {
            width: "100%",
            backgroundSize: "cover",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        bgWrap: {
            position: "fixed",
            background: theme.palette.background.default,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
        },
        headerBg: {},
        halfBg: {},
        fullBg: {},
        bgbar: (_c = {
                backgroundAttachment: "fixed",
                width: "100%",
                top: 0,
                left: 0,
                position: "absolute"
            },
            _c["&.".concat(classes.headerBg)] = {
                height: 64,
            },
            _c["&.".concat(classes.halfBg)] = {
                height: 400,
            },
            _c["&.".concat(classes.fullBg)] = {
                height: "100%",
            },
            _c),
        gradientBg: {
            backgroundImage: (0, exports.gradientBgLight)(theme),
            backgroundAttachment: "fixed",
        },
        solidBg: {
            backgroundColor: theme.palette.background.default,
        },
        decorationWave: (_d = {
                width: "100%",
                height: "auto",
                position: "absolute",
                left: -2,
                bottom: -32,
                transform: "scale(1.1,0.8)",
                transformOrigin: "bottom"
            },
            _d[theme.breakpoints.down("lg")] = {
                display: "none",
            },
            _d),
        decorationPetal: {
            width: "100%",
            height: "auto",
            position: "absolute",
            left: 0,
            top: 0,
        },
        sidebarLayout: {},
        topbarLayout: {},
        mainWrap: (_e = {
                height: "100%",
                position: "relative",
                "& > div": {
                    willChange: "inherit !important", // hack for floating form issue when expaded
                }
            },
            _e["&.".concat(classes.sidebarLayout)] = {
                paddingTop: theme.spacing(6),
            },
            _e["&.".concat(classes.topbarLayout)] = {
                width: "100%",
                marginTop: theme.spacing(3),
            },
            _e),
        wrapSidebarPaddingNone: (_f = {},
            _f["&.".concat(classes.sidebarLayout)] = {
                paddingTop: 0,
            },
            _f),
        preloader: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1000,
            background: "transparent",
            height: 3,
        },
        materialBg: {
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            opacity: 0.5,
        },
        contentPaddingLeft: {
            paddingLeft: theme.spacing(10),
        },
        contentPaddingLeftWorkspace: {
            paddingLeft: theme.spacing(8),
        },
        contentPaddingLeftBig: {
            paddingLeft: theme.spacing(2),
        },
        contentPaddingRight: {
            paddingRight: theme.spacing(10),
        },
        hideApp: {
            display: "none",
        },
        circularProgress: {
            position: "fixed",
            top: "calc(50% - 45px)",
            left: "calc(50% - 45px)",
        },
        brand: {
            height: 54,
            display: "flex",
            padding: "10px 10px 5px",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            "& img": {
                width: 20,
            },
            "& h3": {
                margin: 0,
                fontSize: 16,
                fontWeight: 500,
                paddingLeft: 10,
                color: theme.palette.common.white,
            },
        },
        light: {},
        pageTitle: (_g = {
                padding: theme.spacing(1),
                paddingBottom: theme.spacing(3)
            },
            _g[theme.breakpoints.up("lg")] = {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
            },
            _g["& h4"] = (_h = {
                    fontWeight: 700,
                    textTransform: "capitalize"
                },
                _h[theme.breakpoints.down("lg")] = {
                    marginBottom: theme.spacing(3),
                },
                _h),
            _g),
        darkTitle: {
            color: theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.dark,
        },
        lightTitle: {
            color: theme.palette.common.white,
        },
        clientLayout: {
            height: "100vh",
        },
    });
});
