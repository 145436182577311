"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    snippingToolContainer: {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: "none",
    },
    snippingTool: {
        position: "relative",
        border: "1px dashed rgba(0,0,0,0.5)",
        outlineStyle: "solid",
        outlineWidth: "1500px",
        outlineColor: "rgba(0,0,0,0.2)",
    },
    dragger: {
        pointerEvents: "all",
        position: "absolute",
        zIndex: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        transition: "background-color 0.5s ease",
        "& .MuiSvgIcon-root": {
            opacity: 0.2,
            transition: "opacity 0.5s ease",
        },
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.5)",
            "& .MuiSvgIcon-root": {
                opacity: 1,
            },
        },
    },
    horizontalDragger: {
        cursor: "ew-resize",
        width: "2px",
        top: 0,
        bottom: 0,
    },
    leftDragger: {
        left: -1,
    },
    rightDragger: {
        right: -1,
    },
    verticalDragger: {
        cursor: "ns-resize",
        height: "2px",
        left: 0,
        right: 0,
        flexDirection: "column",
    },
    topDragger: {
        top: -1,
    },
    bottomDragger: {
        bottom: -1,
    },
    snippingToolActions: {
        pointerEvents: "all",
        position: "absolute",
        bottom: 10,
        transform: "translateX(-50%)",
        left: "50%",
        display: "flex",
        gap: theme.spacing(1),
        margin: 0,
        padding: theme.spacing(1),
    },
    snippingToolActionButton: {
        color: "rgba(0, 0, 0, 0.87)",
    },
}); });
