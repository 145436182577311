"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PULSE_NODE_IN_FOCUS_COLOR_CLASS = exports.PULSE_SUBSIDIARY_COLOR_CLASS = exports.PULSE_OWNER_COLOR_CLASS = void 0;
var react_1 = require("react");
exports.PULSE_OWNER_COLOR_CLASS = "pulseOwner";
exports.PULSE_SUBSIDIARY_COLOR_CLASS = "pulseSubsidiary";
exports.PULSE_NODE_IN_FOCUS_COLOR_CLASS = "pulseNodeInFocus";
var createPulseStyle = function (color, className) {
    return ".".concat(className, " {\n        stroke: ").concat(color, ";\n          animation-duration: 2s;\n          animation-name: ").concat(className, "-pulse;\n          animation-iteration-count: infinite;\n          animation-direction: alternate;\n          animation-timing-function: linear;\n      }\n      @keyframes ").concat(className, "-pulse {\n        0% {\n          filter: drop-shadow(0px 0px 12px 4px ").concat(color, ");\n          box-shadow: 0px 0px 12px 4px ").concat(color, ";\n        }\n        100% {\n          filter: drop-shadow(0px 0px 24px 8px ").concat(color, ");\n          box-shadow: 0px 0px 24px 8px ").concat(color, ";\n        }\n      }");
};
var applyPulseStyle = function (color, className) {
    // Find or create a style element in the document head with the dynamic ID
    var styleElementId = "pulse-style-".concat(className);
    var styleElement = document.getElementById(styleElementId);
    if (!styleElement) {
        styleElement = document.createElement("style");
        styleElement.id = styleElementId;
        document.head.appendChild(styleElement);
    }
    // Update the content of the style element
    styleElement.textContent = createPulseStyle(color, className);
};
var removePulseStyle = function (className) {
    var styleElementId = "pulse-style-".concat(className);
    // Find the style element with the specified dynamic ID
    var styleElement = document.getElementById(styleElementId);
    // If the style element exists, remove it from the document
    if (styleElement) {
        styleElement.remove();
    }
};
var useIdentifiedOwnersAndSubsidiaries = function (_a) {
    var nodeInFocusId = _a.nodeInFocusId, owners = _a.owners, subsidiaries = _a.subsidiaries, showNodeInFocus = _a.showNodeInFocus, nodeInFocusColor = _a.nodeInFocusColor, showOwners = _a.showOwners, showSubsidiaries = _a.showSubsidiaries, ownerColor = _a.ownerColor, subsidiaryColor = _a.subsidiaryColor;
    var highlightElements = (0, react_1.useCallback)(function (focusedNodeId, ownerIds, subsidiaryIds) {
        var nodes = document.querySelectorAll(".react-flow__node");
        nodes.forEach(function (node) {
            var id = node.getAttribute("data-id");
            if (!id) {
                return;
            }
            // get children where id is not > div:not(.react-flow__resize-control)
            var innerNode = node.querySelector("div:not(.react-flow__resize-control)");
            if (!innerNode) {
                return;
            }
            if (showNodeInFocus && focusedNodeId === id) {
                innerNode.classList.remove(exports.PULSE_OWNER_COLOR_CLASS);
                innerNode.classList.remove(exports.PULSE_SUBSIDIARY_COLOR_CLASS);
                innerNode.classList.add(exports.PULSE_NODE_IN_FOCUS_COLOR_CLASS);
            }
            else if (showOwners && ownerIds.has(id)) {
                innerNode.classList.remove(exports.PULSE_SUBSIDIARY_COLOR_CLASS);
                innerNode.classList.remove(exports.PULSE_NODE_IN_FOCUS_COLOR_CLASS);
                innerNode.classList.add(exports.PULSE_OWNER_COLOR_CLASS);
            }
            else if (showSubsidiaries && subsidiaryIds.has(id)) {
                innerNode.classList.remove(exports.PULSE_OWNER_COLOR_CLASS);
                innerNode.classList.remove(exports.PULSE_NODE_IN_FOCUS_COLOR_CLASS);
                innerNode.classList.add(exports.PULSE_SUBSIDIARY_COLOR_CLASS);
            }
            else {
                innerNode.classList.remove(exports.PULSE_OWNER_COLOR_CLASS);
                innerNode.classList.remove(exports.PULSE_SUBSIDIARY_COLOR_CLASS);
                innerNode.classList.remove(exports.PULSE_NODE_IN_FOCUS_COLOR_CLASS);
            }
        });
    }, [showNodeInFocus, showOwners, showSubsidiaries]);
    (0, react_1.useEffect)(function () {
        highlightElements(nodeInFocusId, new Set(owners), new Set(subsidiaries));
    }, [owners, subsidiaries, nodeInFocusId, highlightElements]);
    (0, react_1.useEffect)(function () {
        applyPulseStyle(nodeInFocusColor, exports.PULSE_NODE_IN_FOCUS_COLOR_CLASS);
        applyPulseStyle(ownerColor, exports.PULSE_OWNER_COLOR_CLASS);
        applyPulseStyle(subsidiaryColor, exports.PULSE_SUBSIDIARY_COLOR_CLASS);
        return function () {
            removePulseStyle(exports.PULSE_NODE_IN_FOCUS_COLOR_CLASS);
            removePulseStyle(exports.PULSE_OWNER_COLOR_CLASS);
            removePulseStyle(exports.PULSE_SUBSIDIARY_COLOR_CLASS);
        };
    }, []);
    var removeElementHighlights = (0, react_1.useCallback)(function () {
        var allNodes = document.querySelectorAll(".react-flow__node");
        allNodes.forEach(function (n) {
            n.classList.remove(exports.PULSE_OWNER_COLOR_CLASS);
            n.classList.remove(exports.PULSE_SUBSIDIARY_COLOR_CLASS);
            n.classList.remove(exports.PULSE_NODE_IN_FOCUS_COLOR_CLASS);
        });
    }, []);
    return {
        removeElementHighlights: removeElementHighlights,
        applyPulseStyle: applyPulseStyle,
    };
};
exports.default = useIdentifiedOwnersAndSubsidiaries;
