"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var reactFlow_1 = require("@customTypes/reactFlow");
var react_1 = require("react");
var TextService_1 = require("@helpers/flow/label/TextService");
var nodeReducer_1 = require("@pages/Nodes/reducers/nodeReducer");
var useNodePair = function (id1, id2) {
    var generateDummyNode = (0, react_1.useCallback)(function (id, xPosition, yPosition) { return ({
        id: id,
        position: { x: xPosition, y: yPosition },
        data: {
            createdAt: "",
            isDrilldown: false,
            drilldownId: null,
            workspaceTabId: null,
            workspaceStepId: null,
            baseReferenceId: null,
            stepReferenceId: null,
            label: "",
            backgroundColor: "rgba(255,255,255,1)",
            borderColor: "rgba(0,0,0,1)",
            displayName: "",
            figur: reactFlow_1.Figur.ROUNDRECTANGLE,
            id: +id,
            nodeId: 0,
            unitNumber: null,
            labelColor: "rgba(0,0,0,1)",
            fontSize: 10,
            attributeFontSize: 6,
            fontFamily: TextService_1.FontFace.Open_Sans,
            width: 400,
            height: 100,
            attributes: [],
            data_provider: null,
            fitSizeToLabel: false,
            exitLevelPWC: undefined,
            notes: "",
            flag: nodeReducer_1.initialFlag,
            icon: nodeReducer_1.initialIcon,
            documents: [],
            handles: [],
            borderStyle: "solid",
            isBold: false,
            isItalic: false,
            isUnderline: false,
        },
        style: {
            visibility: "hidden",
        },
        width: 400,
        height: 100,
    }); }, []);
    return (0, react_1.useMemo)(function () { return ({
        nodes: [generateDummyNode(id1, 0, 0), generateDummyNode(id2, 200, 200)],
    }); }, []);
};
exports.default = useNodePair;
