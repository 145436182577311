"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var timelineActions_http_1 = require("@pages/Timelines/reducers/timelineActions.http");
var react_1 = require("react");
function useSwimlanesNode(_a) {
    var user = _a.user, id = _a.id, data = _a.data;
    var dispatch = (0, redux_1.useAppDispatch)();
    var currentSwimlaneId = data.swimlaneId;
    var isBelow = data.isBelow;
    var stateSwimlanes = (0, redux_1.useAppSelector)(function (state) { return state.timeline.swimlanes; });
    var swimlanes = (0, react_1.useMemo)(function () { return stateSwimlanes.filter(function (s) { return s.isBelow === isBelow; }); }, [stateSwimlanes, isBelow]);
    var canMoveUp = (0, react_1.useMemo)(function () {
        if (swimlanes.length === 0 || currentSwimlaneId === null)
            return false;
        var swimlaneIndex = swimlanes.findIndex(function (s) { return s.id === currentSwimlaneId; });
        return swimlaneIndex < swimlanes.length;
    }, [currentSwimlaneId, swimlanes]);
    var canMoveDown = (0, react_1.useMemo)(function () {
        if (swimlanes.length === 0)
            return false;
        if (currentSwimlaneId === null)
            return true;
        var swimlaneIndex = swimlanes.findIndex(function (s) { return s.id === currentSwimlaneId; });
        return swimlaneIndex > 0;
    }, [currentSwimlaneId, swimlanes]);
    var onMoveNodeUp = (0, react_1.useCallback)(function () {
        dispatch((0, timelineActions_http_1.onMoveSwimlaneNode)(user, id, +data.id, "up"));
    }, [currentSwimlaneId, dispatch]);
    var onMoveNodeDown = (0, react_1.useCallback)(function () {
        dispatch((0, timelineActions_http_1.onMoveSwimlaneNode)(user, id, +data.id, "down"));
    }, [currentSwimlaneId, dispatch]);
    var onChangeSide = (0, react_1.useCallback)(function () {
        dispatch((0, timelineActions_http_1.onChangeSideNode)(user, id, +data.id));
    }, [dispatch]);
    return {
        canMoveUp: isBelow ? canMoveDown : canMoveUp,
        canMoveDown: isBelow ? canMoveUp : canMoveDown,
        onMoveNodeUp: isBelow ? onMoveNodeDown : onMoveNodeUp,
        onMoveNodeDown: isBelow ? onMoveNodeUp : onMoveNodeDown,
        onChangeSide: onChangeSide,
    };
}
exports.default = useSwimlanesNode;
