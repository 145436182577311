"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var useSlotProps = function (taskGroup) {
    var _a = react_1.default.useState(), selectedRow = _a[0], setSelectedRow = _a[1];
    var _b = react_1.default.useState(), selectedColumn = _b[0], setSelectedColumn = _b[1];
    var _c = react_1.default.useState(null), contextMenu = _c[0], setContextMenu = _c[1];
    var handleClose = (0, react_1.useCallback)(function () {
        setContextMenu(null);
    }, []);
    var handleContextMenu = (0, react_1.useCallback)(function (event) {
        var _a, _b;
        event.preventDefault();
        var taskId = Number(event.currentTarget.getAttribute("data-id"));
        var task = taskGroup.tasks.find(function (t) { return t.id === taskId; });
        if (!task) {
            var gridContainer = event.currentTarget.closest(".detailsPanelContainer");
            var parentId_1 = Number(gridContainer === null || gridContainer === void 0 ? void 0 : gridContainer.getAttribute("data-id"));
            var parent = taskGroup.tasks.find(function (t) { return t.id === parentId_1; });
            task = parent === null || parent === void 0 ? void 0 : parent.tasks.find(function (t) { return t.id === taskId; });
        }
        var closestDataFieldElement = (_a = event.target) === null || _a === void 0 ? void 0 : _a.closest("[data-field]");
        var dataField = closestDataFieldElement === null || closestDataFieldElement === void 0 ? void 0 : closestDataFieldElement.getAttribute("data-field");
        setSelectedColumn((_b = dataField) !== null && _b !== void 0 ? _b : undefined);
        setSelectedRow(task);
        setContextMenu(contextMenu === null
            ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
            : null);
    }, [taskGroup, contextMenu]);
    var slotProps = (0, react_1.useMemo)(function () { return ({
        row: {
            onContextMenu: handleContextMenu,
        },
    }); }, [handleContextMenu]);
    return {
        slotProps: slotProps,
        contextMenu: contextMenu,
        handleClose: handleClose,
        selectedRow: selectedRow,
        selectedColumn: selectedColumn,
        handleContextMenu: handleContextMenu,
    };
};
exports.default = useSlotProps;
