"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePPTXNode = exports.PPTX_VIEW = exports.PPTX_VIEW_NODE_ID = void 0;
var converters_1 = require("@helpers/measuringUnits/converters");
var usePowerPointFitter_1 = require("@hooks/flow/usePowerPointFitter");
exports.PPTX_VIEW_NODE_ID = "powerpoint";
exports.PPTX_VIEW = {
    empty: "empty",
    standard: "standard",
    structure: "structure",
};
var GetPPTXNodeDimensions = function (customer) {
    var _a;
    var _b = (_a = usePowerPointFitter_1.CUSTOMER_SLIDE_DIMENSIONS[customer]) !== null && _a !== void 0 ? _a : usePowerPointFitter_1.CUSTOMER_SLIDE_DIMENSIONS.default, slideWidth = _b.slideWidth, slideHeight = _b.slideHeight;
    return { height: (0, converters_1.inch2px)(slideHeight), width: (0, converters_1.inch2px)(slideWidth) };
};
function CreatePPTXNode(data) {
    var dimensions = GetPPTXNodeDimensions(data.customer);
    return __assign({ id: exports.PPTX_VIEW_NODE_ID, type: "powerpoint", selectable: false, draggable: false, connectable: false, deletable: false, focusable: false, style: { pointerEvents: "none" }, data: __assign(__assign({}, dimensions), data), position: { x: 0, y: 0 } }, dimensions);
}
exports.CreatePPTXNode = CreatePPTXNode;
