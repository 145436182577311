"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSamePositionAsDefaultHandle = exports.isDefaultHandle = exports.DefaultHandleCoordinates = exports.DefaultHandleIds = exports.DefaultRightHandles = exports.DefaultLeftHandles = exports.DefaultBottomHandles = exports.DefaultTopHandles = void 0;
var useHandles_1 = __importStar(require("@hooks/nodes/useHandles"));
var react_1 = __importStar(require("react"));
var reactflow_1 = require("reactflow");
exports.DefaultTopHandles = {
    top: "top",
    topLeft: "topLeft",
    topRight: "topRight",
};
exports.DefaultBottomHandles = {
    bottom: "bottom",
    bottomLeft: "bottomLeft",
    bottomRight: "bottomRight",
};
exports.DefaultLeftHandles = {
    left: "left",
    leftTop: "leftTop",
    leftBottom: "leftBottom",
};
exports.DefaultRightHandles = {
    right: "right",
    rightTop: "rightTop",
    rightBottom: "rightBottom",
};
exports.DefaultHandleIds = __assign(__assign(__assign(__assign({}, exports.DefaultTopHandles), exports.DefaultBottomHandles), exports.DefaultLeftHandles), exports.DefaultRightHandles);
exports.DefaultHandleCoordinates = (_a = {},
    _a[exports.DefaultHandleIds.top] = { x: useHandles_1.handleCoordinates.middle, y: 0 },
    _a[exports.DefaultHandleIds.bottom] = { x: useHandles_1.handleCoordinates.middle, y: 0 },
    _a[exports.DefaultHandleIds.left] = { x: 0, y: useHandles_1.handleCoordinates.middle },
    _a[exports.DefaultHandleIds.right] = { x: 0, y: useHandles_1.handleCoordinates.middle },
    _a[exports.DefaultHandleIds.topLeft] = { x: useHandles_1.handleCoordinates.leftQuarter, y: 0 },
    _a[exports.DefaultHandleIds.topRight] = { x: useHandles_1.handleCoordinates.leftThreeQuarter, y: 0 },
    _a[exports.DefaultHandleIds.leftTop] = { x: 0, y: useHandles_1.handleCoordinates.topQuarter },
    _a[exports.DefaultHandleIds.leftBottom] = { x: 0, y: useHandles_1.handleCoordinates.topThreeQuarter },
    _a[exports.DefaultHandleIds.rightTop] = { x: 0, y: useHandles_1.handleCoordinates.topQuarter },
    _a[exports.DefaultHandleIds.rightBottom] = { x: 0, y: useHandles_1.handleCoordinates.topThreeQuarter },
    _a[exports.DefaultHandleIds.bottomLeft] = { x: useHandles_1.handleCoordinates.leftQuarter, y: 0 },
    _a[exports.DefaultHandleIds.bottomRight] = { x: useHandles_1.handleCoordinates.leftThreeQuarter, y: 0 },
    _a);
var isDefaultHandle = function (handleId) {
    return exports.DefaultHandleIds[handleId] !== undefined;
};
exports.isDefaultHandle = isDefaultHandle;
var isSamePositionAsDefaultHandle = function (handle, defaultHandleId) {
    if (!handle)
        return false;
    if (handle.position === reactflow_1.Position.Top &&
        exports.DefaultTopHandles[defaultHandleId]) {
        var defaultCoordinates = exports.DefaultHandleCoordinates[defaultHandleId];
        return handle.x === defaultCoordinates.x && handle.y === defaultCoordinates.y;
    }
    if (handle.position === reactflow_1.Position.Bottom &&
        exports.DefaultBottomHandles[defaultHandleId]) {
        var defaultCoordinates = exports.DefaultHandleCoordinates[defaultHandleId];
        return handle.x === defaultCoordinates.x && handle.y === defaultCoordinates.y;
    }
    if (handle.position === reactflow_1.Position.Left &&
        exports.DefaultLeftHandles[defaultHandleId]) {
        var defaultCoordinates = exports.DefaultHandleCoordinates[defaultHandleId];
        return handle.x === defaultCoordinates.x && handle.y === defaultCoordinates.y;
    }
    if (handle.position === reactflow_1.Position.Right &&
        exports.DefaultRightHandles[defaultHandleId]) {
        var defaultCoordinates = exports.DefaultHandleCoordinates[defaultHandleId];
        return handle.x === defaultCoordinates.x && handle.y === defaultCoordinates.y;
    }
    return false;
};
exports.isSamePositionAsDefaultHandle = isSamePositionAsDefaultHandle;
function DoubleSideHandle(props) {
    var id = props.id, showContext = props.showContext, isMoving = props.isMoving, numberOfHandles = props.numberOfHandles;
    var _a = (0, useHandles_1.default)(id, showContext, isMoving), handleStyleSideBottom = _a.handleStyleSideBottom, handleStyleSideTop = _a.handleStyleSideTop, handleStyleTop = _a.handleStyleTop, handleStyleBottom = _a.handleStyleBottom, handleStyleLeft = _a.handleStyleLeft, handleStyleRight = _a.handleStyleRight, handleStyleTopClass = _a.handleStyleTopClass, handleStyleBottomClass = _a.handleStyleBottomClass, handleStyleLeftClass = _a.handleStyleLeftClass, handleStyleRightClass = _a.handleStyleRightClass, handleStyleTopBottomLeft = _a.handleStyleTopBottomLeft, handleStyleTopBottomRight = _a.handleStyleTopBottomRight, handleClick = _a.handleClick, isEasyConnect = _a.isEasyConnect;
    var updateNodeInternals = (0, reactflow_1.useUpdateNodeInternals)();
    var isNodesConnectable = (0, reactflow_1.useStore)(function (s) { return s.nodesConnectable; });
    var handleHandleClick = (0, react_1.useCallback)(function (e) {
        e.stopPropagation();
        var handleId = e.target.getAttribute("data-handleid");
        isNodesConnectable && (handleClick === null || handleClick === void 0 ? void 0 : handleClick(handleId));
    }, [handleClick, isNodesConnectable]);
    (0, react_1.useEffect)(function () {
        updateNodeInternals(id.toString());
    }, [isEasyConnect]);
    var handles = [
        {
            handleId: exports.DefaultHandleIds.top,
            position: reactflow_1.Position.Top,
            style: handleStyleTop,
            className: handleStyleTopClass,
        },
        {
            handleId: exports.DefaultHandleIds.left,
            position: reactflow_1.Position.Left,
            style: handleStyleLeft,
            className: handleStyleLeftClass,
        },
        {
            handleId: exports.DefaultHandleIds.right,
            position: reactflow_1.Position.Right,
            style: handleStyleRight,
            className: handleStyleRightClass,
        },
        {
            handleId: exports.DefaultHandleIds.bottom,
            position: reactflow_1.Position.Bottom,
            style: handleStyleBottom,
            className: handleStyleBottomClass,
        },
    ];
    var additionalHandles = [
        {
            handleId: exports.DefaultHandleIds.topLeft,
            position: reactflow_1.Position.Top,
            style: __assign(__assign({}, handleStyleTopBottomLeft), handleStyleTop),
            className: handleStyleTopClass,
        },
        {
            handleId: exports.DefaultHandleIds.topRight,
            position: reactflow_1.Position.Top,
            style: __assign(__assign({}, handleStyleTopBottomRight), handleStyleTop),
            className: handleStyleTopClass,
        },
        {
            handleId: exports.DefaultHandleIds.leftTop,
            position: reactflow_1.Position.Left,
            style: __assign(__assign({}, handleStyleSideTop), handleStyleLeft),
            className: handleStyleLeftClass,
        },
        {
            handleId: exports.DefaultHandleIds.leftBottom,
            position: reactflow_1.Position.Left,
            style: __assign(__assign({}, handleStyleSideBottom), handleStyleLeft),
            className: handleStyleLeftClass,
        },
        {
            handleId: exports.DefaultHandleIds.rightTop,
            position: reactflow_1.Position.Right,
            style: __assign(__assign({}, handleStyleSideTop), handleStyleRight),
            className: handleStyleRightClass,
        },
        {
            handleId: exports.DefaultHandleIds.rightBottom,
            position: reactflow_1.Position.Right,
            style: __assign(__assign({}, handleStyleSideBottom), handleStyleRight),
            className: handleStyleRightClass,
        },
        {
            handleId: exports.DefaultHandleIds.bottomLeft,
            position: reactflow_1.Position.Bottom,
            style: __assign(__assign({}, handleStyleTopBottomLeft), handleStyleBottom),
            className: handleStyleBottomClass,
        },
        {
            handleId: exports.DefaultHandleIds.bottomRight,
            position: reactflow_1.Position.Bottom,
            style: __assign(__assign({}, handleStyleTopBottomRight), handleStyleBottom),
            className: handleStyleBottomClass,
        },
    ];
    var renderHandles = numberOfHandles === 1
        ? handles
        : numberOfHandles === 2
            ? additionalHandles
            : __spreadArray(__spreadArray([], handles, true), additionalHandles, true);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        isEasyConnect && (react_1.default.createElement(reactflow_1.Handle, { position: reactflow_1.Position.Bottom, type: "source", id: "easyHandle", style: {
                position: "absolute",
                left: "50%",
                top: "5%",
                width: "90%",
                height: "90%",
                backgroundColor: "transparent",
                borderColor: "transparent",
            } })),
        renderHandles.map(function (_a) {
            var handleId = _a.handleId, position = _a.position, style = _a.style, className = _a.className;
            return (react_1.default.createElement(reactflow_1.Handle, { key: handleId, className: className, style: style, type: "source", id: handleId, position: position, onClick: handleHandleClick }));
        })));
}
exports.default = (0, react_1.memo)(DoubleSideHandle);
