"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var SaveContainer = (0, material_1.styled)(material_1.Box)({
    position: "absolute",
    bottom: 16,
    right: 16,
    display: "flex",
    gap: "4px",
});
exports.default = SaveContainer;
